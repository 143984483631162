import React, {Fragment} from 'react';
import ScrollUp from "../items/scroll-up-button"
import PopupBasket from "./popup-basket";
import ScrollToTop from "./scroll-to-top-fix";
import PopupFeedback from "./popup-feedback";
import Notification from "./notification";
import {Helmet} from "react-helmet"
import {connect} from 'react-redux';
import PopupMenu from "./popup-menu";

class MainContent extends React.PureComponent  {
    constructor(props) {
        super(props);
    }

    render() {
        const {children, seo} = this.props;

        return (
            <Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{seo.title}</title>
                    <meta name="description" content={seo.description} />
                    <meta name="keywords" content={seo.keywords} />
                </Helmet>

                <ScrollUp/>
                <div className="main-content">
                    {children}
                </div>

                <PopupBasket />
                <ScrollToTop />
                <PopupFeedback />
                <PopupMenu />
                <Notification />
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        seo: state.general.seo
    }
};

export default connect(mapStateToProps, null)(MainContent);
