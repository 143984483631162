import React from 'react';
import {Urls} from "../../utils";
import {Link} from 'react-router-dom';
import AnimationBase from "../items/animation-base";

const OtherGoods = (props) => {
    const {goods} = props;

    if (goods.length > 4) {
        const swiper =  new Swiper('.swiper-container.swiper-goods-modification',
            {
                loop: false,
                slidesPerView: 2,
                spaceBetween: 24,
                keyboard: {
                    enabled: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next.swiper-goods-modification__next',
                    prevEl: '.swiper-button-prev.swiper-goods-modification__prev',
                },
                breakpoints: {
                    800: {
                        slidesPerView: 4,
                        spaceBetween: 24,
                    }

                }
            });

        return (<AnimationBase classDiv={`swiper-goods-modification_outer`}>
            <div className="swiper-container swiper-goods-modification">
                <div className="swiper-wrapper swiper-goods-mod_wrapper">
                    {goods.map((good, index) => {
                        return <Link
                                    key={`good-${index}`}
                                    to={`/${Urls.GOODS}/${good.slug}`}
                                    itemScope=""
                                    itemType="https://schema.org/ImageObject"
                                    className="swiper-slide swiper-goods-mod_swiper-slide goods-series__item"
                                >
                                    <img itemProp="contentUrl" loading="lazy" className="goods-series__img" src={good.imageUrl} alt={good.title} />
                                    <span itemProp="name" className="goods-series__title">{good.title}</span>
                                </Link>;
                        })
                    }
                </div>
            </div>
            <div className="swiper-button-next swiper-goods-modification__next"></div>
            <div className="swiper-button-prev swiper-goods-modification__prev"></div>
        </AnimationBase>);

    } else {
        return (<AnimationBase classDiv={`goods-modification`}>
            {goods.map((good, index) => {
                return (
                    <Link
                        key={`good-${index}`}
                        to={`/${Urls.GOODS}/${good.slug}`}
                        className="goods-series__item"
                        itemScope=""
                        itemType="https://schema.org/ImageObject">
                            <img itemProp="contentUrl" loading="lazy" className="goods-series__img" src={good.imageUrl} alt={good.title} />
                            <span itemProp="name" className="goods-series__title">{good.title}</span>
                    </Link>
                )
            })
            }
        </AnimationBase>);
    }
};

export default OtherGoods;
