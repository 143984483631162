import React from 'react';

const CardInfo = (props) => {
    const {title, description = false, materials, dimensions = false, category= "прочее"} = props;

    return (
        <div className="card-info">
            {category && <div itemProp="category" className="card-info__category">{category}</div>}
            <div itemProp="name" className="card-info__title">{title}</div>
            {dimensions && <div className="card-info__size">Ш x В x Г (мм)</div>}
            {dimensions && <div itemProp="size" className="card-info__size_values">{dimensions}</div>}
            {description && <div itemProp="description" className="card-info__description">{description}</div>}

        </div>
    );
};

export default CardInfo;
