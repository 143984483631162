import React, { Fragment, Component } from "react";
import Header from "../items/header";
import MainContent from "../items/main-content";
import Footer from "../items/footer";
import FormGoodsCard from "../items/form-goods-card";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { ActionCreator, Operations } from "../../reducers/goods/goods";
import { ActionCreator as feedbackActionCreator } from "../../reducers/feedback/feedback";
import { bindActionCreators } from "redux";
import { Urls } from "../../utils";
import { extractionFullImg } from "../../utils/extractionFullImg";
import { Helmet } from "react-helmet";
import AnimationRight from "../items/animation-right";
import AnimationBase from "../items/animation-base";
import NextCard from "../items/next-card";
import OtherGoods from "../items/other-goods";
import GoodSlides from "../items/good-slides";
import GoodSlidesMobile from "../items/good-slides-mobile";
import StickyBox from "react-sticky-box";
import Breadcrumbs from "../items/breadcrumbs";

class GoodsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowTooltipe: false,
      currentMainImgShow: ``,
      currentWidthWindow: window.innerWidth,
    };

    this.closeTooltipe = this.closeTooltipe.bind(this);
    this.openTooltipe = this.openTooltipe.bind(this);
    this.setCurrentMainImg = this.setCurrentMainImg.bind(this);
    this.resizeWindowHandler = this.resizeWindowHandler.bind(this);
  }

  resizeWindowHandler() {
    this.setState({
      currentWidthWindow: window.innerWidth,
    });
  }

  closeTooltipe() {
    this.setState({
      isShowTooltipe: false,
    });
  }

  openTooltipe() {
    this.setState({
      isShowTooltipe: true,
    });
  }

  componentDidMount() {
    const { slug } = this.props;
    const { getGoodsPageInfo } = this.props;

    getGoodsPageInfo(slug);
  }

  componentDidUpdate(prevState) {
    const { slug } = this.props;
    const { getGoodsPageInfo, clearGoodsPageInfo } = this.props;
    if (prevState.slug !== slug) {
      clearGoodsPageInfo();
      getGoodsPageInfo(slug);
    }
  }

  componentWillUnmount() {
    const { clearGoodsPageInfo } = this.props;
    clearGoodsPageInfo();
  }

  setCurrentMainImg(color) {
    this.setState({
      currentMainImgShow: color.imageUrl,
      currentMainImgBigShow: color.imgBigUrl,
    });
  }

  render() {
    const { goodsPageInfo, openFeedbackPopup } = this.props;

    if (!goodsPageInfo) {
      return (
        <Fragment>
          <Header isActive={true} />
          <MainContent></MainContent>
          <Footer />
        </Fragment>
      );
    }

    const { currentMainImgShow, currentMainImgBigShow, currentWidthWindow } =
      this.state;

    const {
      seriesSlug,
      seo,
      otherGoods,
      otherCategories,
      next_series,
      slides,
      breadcrumbItems,
      breadcrumbCurrent,
    } = goodsPageInfo;

    const urlGoBack =
      seriesSlug !== null ? `${Urls.SERIES}/${seriesSlug}` : Urls.CATALOG;

    const BREAK_POINT_FOR_SLIDER_M0BILE = 980;

    const fullImages = extractionFullImg(slides, currentMainImgBigShow);

    return (
      <Fragment>
        <Header isActive={true} />
        <MainContent>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{seo.title}</title>
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
          </Helmet>
          <Breadcrumbs items={breadcrumbItems} activeItem={breadcrumbCurrent} />
          <div className="card-goods-wrapper">
            <AnimationRight>
              <div
                itemScope=""
                itemType="https://schema.org/Product"
                className="card-goods container grid-container"
              >
                {currentWidthWindow >= BREAK_POINT_FOR_SLIDER_M0BILE && (
                  <div className="card-goods__gallery-wrap">
                    <GoodSlides
                      slides={slides}
                      mainimg={currentMainImgShow}
                      fullImages={fullImages}
                    />
                  </div>
                )}

                {currentWidthWindow < BREAK_POINT_FOR_SLIDER_M0BILE && (
                  <div className="card-goods__slider-wrap">
                    <GoodSlidesMobile
                      slides={slides}
                      mainimg={currentMainImgShow}
                    />
                  </div>
                )}

                <div className="card-goods__content">
                  <StickyBox offsetTop={20} offsetBottom={20}>
                    <FormGoodsCard
                      goodsPageInfo={goodsPageInfo}
                      setCurrentMainImg={this.setCurrentMainImg}
                      openFeedbackPopup={openFeedbackPopup}
                    />
                  </StickyBox>
                </div>
              </div>
            </AnimationRight>
          </div>

          {otherGoods.length > 0 && (
            <div className="goods-modification-wrap container grid-container">
              <div className="goods-modification-title">
                {" "}
                <h3>Другие модификации</h3>{" "}
              </div>
              <OtherGoods goods={otherGoods} />
            </div>
          )}

          {/*{otherCategories.length > 0 && <div className="series-categories-wrap container grid-container">*/}
          {/*    <div className="series-categories-title"> <h3>Другие категории товаров</h3> </div>*/}
          {/*    <AnimationBase classDiv={`series-categories`}>*/}
          {/*        {otherCategories.map((cat, index) => {*/}
          {/*            return <Link*/}
          {/*                key={`cat-${index}`}*/}
          {/*                to={`/catalog#${cat.slug}`}*/}
          {/*                className="series-category-thumb"*/}
          {/*            >*/}
          {/*                <img className="series-category__img" src={cat.imageUrl}/>*/}
          {/*                <span className="series-category__title">{cat.title}</span>*/}
          {/*            </Link>;*/}
          {/*        })}*/}
          {/*    </AnimationBase>*/}
          {/*</div>}*/}
        </MainContent>
        <NextCard data={next_series} />
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  goodsPageInfo: state.goods.goodsPageInfo,
});

const mapDispatchToProps = (dispatch) => ({
  getGoodsPageInfo: bindActionCreators(Operations.getGoodsPageInfo, dispatch),
  clearGoodsPageInfo: () => dispatch(ActionCreator.clearGoodsPageInfo()),
  openFeedbackPopup: () => dispatch(feedbackActionCreator.openFeedbackPopup()),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoodsPage);
