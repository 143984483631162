import {combineReducers} from 'redux';

import {reducer as series} from './series/series';
import {reducer as projects} from './projects/projects';
import {reducer as catalog} from './catalog/catalog';
import {reducer as goods} from './goods/goods';
import {reducer as basket} from './basket/basket';
import {reducer as feedback} from './feedback/feedback';
import {reducer as notification} from './notification/notification';
import {reducer as general} from './general/general';
import {reducer as menu} from './popup-menu/menu';
import {reducer as instagram} from './instagram/instagram';

export default combineReducers(
    {
        series,
        projects,
        catalog,
        goods,
        basket,
        feedback,
        notification,
        general,
        menu,
        instagram
    }
);
