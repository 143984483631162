import React, {Fragment, PureComponent} from 'react';
import Header from "../items/header";
import MainSlider from "../items/main-slider";
import Footer from "../items/footer";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Operations} from "../../reducers/series/series";
import {Operations as ProjectsOperations} from "../../reducers/projects/projects";
import {Operations as InstaOperations} from "../../reducers/instagram/instagram";
import MainContent from "../items/main-content";
import Catalog from "../items/catalog";
import {Urls} from "../../utils";
import {Link} from 'react-router-dom'
import MainSliderMobile from "../items/main-slider-mobile";
import CatalogSliderMobile from "../items/catalog-slider-mobile";
import AnimationBase from "../items/animation-base";
import InstaItem from "../items/insta-item";
import ProjectsList from "../items/projects_list";

class HomePage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentWidthWindow: window.innerWidth
        };

        this.resizeWindowHandler = this.resizeWindowHandler.bind(this);
    }

    resizeWindowHandler() {
        this.setState({
            currentWidthWindow: window.innerWidth
        });
    }

    componentDidMount() {
        const {getSmallHomeSeries, getBigHomeSeries, getHomeProjects, getInstagramMedia} = this.props;
        getSmallHomeSeries();
        getBigHomeSeries();
        getHomeProjects();
        getInstagramMedia();

        window.addEventListener('resize', this.resizeWindowHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindowHandler);
    }

    render() {
        const {smallHomeSeries, instagramMedia, contacts} = this.props;
        const {homeProjects} = this.props;
        const {currentWidthWindow} = this.state;

        const BREAK_POINT_FOR_SLIDER_MIBILE = 1100;
        const BREAK_POINT_FOR_MARGIN_BOTTOM = 900;
        const BREAK_POINT_FOR_CARDS_SLIDER_MOBILE = 600;

        const marginBottomSection = currentWidthWindow > BREAK_POINT_FOR_MARGIN_BOTTOM ? '12.4rem' : '9.6rem';

        return (
            <Fragment>
                <Header/>
                <MainContent>
                    <div className="main-slider-wrapper">
                        <div className="container grid-container home-slogan-wrap">
                            <AnimationBase classDiv="home-slogan"> Красивая мебель для ваших улиц </AnimationBase>
                        </div>

                        {currentWidthWindow >= BREAK_POINT_FOR_SLIDER_MIBILE && <div className="home-slider-wrapper">
                            <MainSlider/>
                        </div>}
                        {currentWidthWindow < BREAK_POINT_FOR_SLIDER_MIBILE && <MainSliderMobile/>}
                    </div>
                    <div className="container grid-container" style={{marginBottom: marginBottomSection}}>
                        <AnimationBase classDiv="promo-text-center promo-text-center-first">
                            <Link to={`/${Urls.CATALOG}`} className="accent-in-text">Придумываем и производим</Link> городскую мебель: помогаем общественным пространствам быть в центре внимания
                        </AnimationBase>
                    </div>
                    <div className="container grid-container cards-mobile-slider" style={{marginBottom: marginBottomSection}}>
                        {currentWidthWindow >= BREAK_POINT_FOR_CARDS_SLIDER_MOBILE && <Catalog data={smallHomeSeries} showLine={false}/>}
                        {currentWidthWindow < BREAK_POINT_FOR_CARDS_SLIDER_MOBILE && <CatalogSliderMobile slides={smallHomeSeries}/>}
                    </div>
                    <div className="container grid-container">
                        {homeProjects.length > 0 && <ProjectsList projects={homeProjects}/> }
                    </div>
                    {instagramMedia.length > 0 && <div className="container grid-container">
                        <AnimationBase classDiv="promo-text-center">
                            Вы можете посмотреть все серии мебели
                            <br/>
                            в нашем <Link to={`/${Urls.CATALOG}`} className="accent-in-text">каталоге</Link>
                        </AnimationBase>
                        <InstaItem instagramMedia={instagramMedia} contacts={contacts}></InstaItem>
                    </div>}
                </MainContent>
                <Footer/>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        homeProjects: state.projects.homeProjects,
        smallHomeSeries: state.series.smallHomeSeries,
        instagramMedia: state.instagram.instagramMedia,
        contacts: state.general.contacts
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getSmallHomeSeries: bindActionCreators(Operations.getSmallHomeSeries, dispatch),
        getBigHomeSeries: bindActionCreators(Operations.getBigHomeSeries, dispatch),
        getHomeProjects: bindActionCreators(ProjectsOperations.getHomeProjects, dispatch),
        getInstagramMedia: bindActionCreators(InstaOperations.getInstagramMedia, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
