import axios from 'axios';

const HTTP_STATUS = {
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    NETWORK_FAILED: 500
};

const createAPI = (dispatch) => {

    const api = axios.create({
        timeout: 5000,
        withCredentials: true
    });

    const onSuccess = (response) => response;
    const onFail = (error) => {
        return error;
    };

    api.interceptors.response.use(onSuccess, onFail);

    return api;
};

export default createAPI;
