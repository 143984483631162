import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {ActionCreator} from "../../reducers/projects/projects";
import {bindActionCreators} from "redux";
import {Operations} from "../../reducers/projects/projects";
import {INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER, Urls} from '../../utils'

import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

class MainSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            intervalId: null,
            toggleChangeSlide: false
        };
    }

    goToNextSlide = () => {
        const {setSlides, slides, currentIndex, setCurrentIndex} = this.props;

        let newIndex;
        if (currentIndex >= slides.length) {
            newIndex = 1;
        } else {
            newIndex = currentIndex + 1;
        }

        this.setState(prevState => ({
            toggleChangeSlide: !prevState.toggleChangeSlide
        }));

        const newSlides = [];
        const firstSlide = slides.filter((slide) => slide.slideIndex === newIndex);


        newSlides.push(firstSlide[0]);
        newSlides.push(...slides.filter((slide) => slide.slideIndex !== newIndex).sort((a, b) => a.slideIndex - b.slideIndex));

        setCurrentIndex(newIndex);
        setSlides(newSlides);
    };

    handleClickSlide = (evt, clickSlideIndex) => {
        clearInterval(this.state.intervalId);

        const intervalId = setInterval(this.goToNextSlide, INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER);
        this.setState({intervalId: intervalId});

        const {setSlides, slides, setCurrentIndex} = this.props;

        evt.preventDefault();

        const newSlides = [];
        const firstSlide = slides.filter((slide) => slide.slideIndex === clickSlideIndex);

        this.setState(prevState => ({
            toggleChangeSlide: !prevState.toggleChangeSlide
        }));

        newSlides.push(firstSlide[0]);
        newSlides.push(...slides.filter((slide) => slide.slideIndex !== clickSlideIndex).sort((a, b) => a.slideIndex - b.slideIndex));

        setCurrentIndex(clickSlideIndex);
        setSlides(newSlides);
    };

    onMouseOverSlides() {
        clearInterval(this.state.intervalId);
    }

    onMouseLeaveSlides() {
        clearInterval(this.state.intervalId);
        const intervalId = setInterval(this.goToNextSlide, INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER);
        this.setState({intervalId: intervalId});
    }

    componentDidMount() {
        const {getMainSlider} = this.props;
        getMainSlider();
        const intervalId = setInterval(this.goToNextSlide, INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER);
        this.setState({intervalId: intervalId});
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    render() {
        const {slides, currentIndex} = this.props;
        const {toggleChangeSlide} = this.state;

        if (!slides || !slides[0]) {
            return null;
        }

        return (
            <Fragment>
                <div
                    className="custom-slider"
                    onMouseEnter={() => this.onMouseOverSlides()}
                    onMouseLeave={() => this.onMouseLeaveSlides()}
                >
                    <ReactCSSTransitionGroup
                        transitionName={`animation-slide`}
                        transitionAppear={true}
                        transitionAppearTimeout={700}
                        transitionEnterTimeout={700}
                        transitionLeaveTimeout={700}
                    >
                        {toggleChangeSlide && slides.map((slide, index) => {
                            return <a
                                    className={`custom-slider__slide custom-slider__slide-${index + 1}`}
                                    onClick={(evt) => this.handleClickSlide(evt, slide.slideIndex)}
                                    key={index}
                                >
                                  <img src={slide.imageUrl} alt={slide.title}/>
                                </a>;
                        })}
                    </ReactCSSTransitionGroup>
                    <ReactCSSTransitionGroup
                        transitionName={`animation-slide`}
                        transitionAppear={true}
                        transitionAppearTimeout={700}
                        transitionEnterTimeout={700}
                        transitionLeaveTimeout={700}
                    >
                        {!toggleChangeSlide && slides.map((slide, index) => {
                            return <a
                                className={`custom-slider__slide custom-slider__slide-${index + 1}`}
                                onClick={(evt) => this.handleClickSlide(evt, slide.slideIndex)}
                                key={index}
                            >
                                <img src={slide.imageUrl} alt={slide.title}/>
                            </a>;
                        })}
                    </ReactCSSTransitionGroup>
                    <div className="img-for-adaptive-hidden">
                        <img src={slides[0].imageUrl} alt={slides[0].title}/>
                    </div>
                    <ReactCSSTransitionGroup
                        component={Fragment}
                        transitionName={`animation-slide-title`}
                        transitionEnterTimeout={700}
                        transitionLeaveTimeout={300}
                    >
                        {toggleChangeSlide && <div className="custom-slider__title">
                            <Link className="custom-slider__title-link" to={(slides[0].isSeries) ? `/${Urls.SERIES}/${slides[0].slug}` : `/${Urls.PROJECTS}/${slides[0].slug}`}>
                                <span dangerouslySetInnerHTML={{ __html: slides[0].title }} />
                            </Link>
                        </div>}
                    </ReactCSSTransitionGroup>
                    <ReactCSSTransitionGroup
                        component={Fragment}
                        transitionName={`animation-slide-title`}
                        transitionEnterTimeout={700}
                        transitionLeaveTimeout={300}
                    >
                        {!toggleChangeSlide && <div className="custom-slider__title">
                            <Link className="custom-slider__title-link" to={(slides[0].isSeries) ? `/${Urls.SERIES}/${slides[0].slug}` : `/${Urls.PROJECTS}/${slides[0].slug}`} >
                                <span dangerouslySetInnerHTML={{ __html: slides[0].title }} />
                            </Link>
                        </div>}
                    </ReactCSSTransitionGroup>
                </div>

                {/*/!*<button className="custom-slider__next" onClick={this.goToNextSlide}>next</button>*!/*/}
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        slides: state.projects.slides,
        currentIndex: state.projects.currentIndex
    };
};

const mapDispatchToProps = (dispatch) => ({
    getMainSlider: bindActionCreators(Operations.getMainSlider, dispatch),
    setSlides: bindActionCreators(ActionCreator.setSlides, dispatch),
    setCurrentIndex: bindActionCreators(ActionCreator.setCurrentIndex, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MainSlider);
