import React, {useState, Fragment} from 'react';
import {Link} from 'react-router-dom';
import AnimationBase from "../items/animation-base";
import {Urls} from "../../utils";

const CatalogCategories = (props) => {


    const {categories} = props;
    const {currentCategoryTitle} = props;


    const [active, setActive] = useState(false);


    if (!categories.length) {
        return null;
    }
    return (
        <Fragment>
            <div className={active===true ? "catalog-category-filter-wrap is--open" : "catalog-category-filter-wrap"}>
                <AnimationBase><div className="catalog-category-filter-current" onClick={() => setActive(!active)} >{currentCategoryTitle}</div></AnimationBase>
                <ul className="catalog-category-filter-list">
                    {categories.map((category, index) => {
                        return <li className={ currentCategoryTitle === category.title ? "catalog-category-filter-list-item--current" : "catalog-category-filter-list-item" } key={`category-${index}`}>
                              <Link to={`#${category.slug}`} className="catalog-category-filter-list--link" onClick={() => { props.onCurrentCategoryChange(category.title); setActive(false)} }>{category.title}</Link>
                            </li>
                    })}
                    <li className={currentCategoryTitle === 'Вся продукция' ? "catalog-category-filter-list-item--current" : "catalog-category-filter-list-item"}>
                       <Link to={`/catalog`} className="catalog-category-filter-list--link" onClick={() => { props.onCurrentCategoryChange('Вся продукция'); setActive(false)} }>Вся продукция</Link>
                    </li>

                </ul>

            </div>
        </Fragment>
    );
};

export default CatalogCategories;