import React, {Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

const Tooltipe = (props) => {
    const {children, isShow, closeTooltipe} = props;

    return (
        <ReactCSSTransitionGroup
            component={Fragment}
            transitionName={`opacity-animation`}
            transitionEnterTimeout={700}
            transitionLeaveTimeout={700}
        >
            {isShow && <div className="tooltipe">
                <div className="tooltipe__text">{children}</div>
                <div
                    className="tooltipe__close"
                    onClick={closeTooltipe}
                >
                    <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 19.72L11.3394 12.4537C12.8069 11.4068 12.8069 9.31316 11.3394 8.26632L1 1" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                        <path d="M26.9986 1L16.6529 8.26632C15.1938 9.31316 15.1938 11.4068 16.6529 12.4537L26.9323 19.72" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>}
        </ReactCSSTransitionGroup>
    );
};

export default Tooltipe;
