import React from "react"
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import HomePage from './pages/home-page';
import { connect } from 'react-redux';
import AboutPage from "./pages/about-page";
import CatalogPage from "./pages/catalog-page";
import ProjectsPage from "./pages/projects-page";
import ContactsPage from "./pages/contacts-page";
import SeriesPage from "./pages/series-page";
import GoodsPage from "./pages/goods-page";
import {Urls} from "../utils";
import ProjectPage from "./pages/project-page";
import NotFoundPage from "./pages/not_found_page";
import SearchPage from "./pages/search-page";
import CustomPage from './pages/custom-page';
import {Operations} from "../reducers/general/general";

class Index extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {getContacts, getSeo, getFootermenu} = this.props;
        getContacts();
        getSeo();
        getFootermenu();
    }

    render () {

        return (
            <Router>
                <Switch>
                    <Route exact path="/" render={() => <HomePage />} />
                    <Route exact path={`/${Urls.ABOUT}`} render={() => <AboutPage />} />
                    <Route exact path={`/${Urls.CATALOG}`} render={() => <CatalogPage />} />

                    <Route exact path={`/${Urls.SERIES}/:slug`} render={({match}) => {
                        return <SeriesPage slug={match.params.slug} key={Math.random()}/>;
                    }} />

                    <Route exact path={`/${Urls.GOODS}/:slug`} render={({match}) => {
                        return <GoodsPage slug={match.params.slug}/>;
                    }} />

                    <Route exact path={`/${Urls.PROJECTS}`} render={() => <ProjectsPage />} />

                    <Route exact path={`/${Urls.PROJECTS}/:slug`} render={({match}) => {
                        return <ProjectPage slug={match.params.slug} key={Math.random()}/>;
                    }} />

                    <Route exact path={`/${Urls.PAGES}/:slug`} render={({match}) => {
                        return <CustomPage slug={match.params.slug}/>;
                    }} />

                    <Route exact path={`/${Urls.CONTACTS}`} render={() => <ContactsPage />} />
                    <Route exact path={`/${Urls.SEARCH}`} render={() => <SearchPage />} />
                    <Route render={() => <NotFoundPage/>} />
                </Switch>
            </Router>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    getContacts: () => dispatch(Operations.getContacts()),
    getSeo: () => dispatch(Operations.getSeo()),
    getFootermenu: () => dispatch(Operations.getFootermenu())
});

export default connect(null, mapDispatchToProps)(Index);