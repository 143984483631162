import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from "axios";
import {ActionCreator} from "../../reducers/basket/basket";
import {ActionCreator as ActionCreatorNotification} from "../../reducers/notification/notification";
import CardInfo from "../items/card-info";
import Tooltipe from "../items/tooltipe";

class FormGoodsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fieldNumber: 1,
            selectedColor: null,
            isShowTooltipe: false
        };

        this.onPrevHandler = this.onPrevHandler.bind(this);
        this.onNextHandler = this.onNextHandler.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.closeTooltipe = this.closeTooltipe.bind(this);
        this.openTooltipe = this.openTooltipe.bind(this);
    }

    closeTooltipe() {
        this.setState({
            isShowTooltipe: false
        });
    }

    openTooltipe() {
        this.setState({
            isShowTooltipe: true
        });
    }

    onPrevHandler(evt) {
        evt.preventDefault();
        const {fieldNumber} = this.state;
        let stepPrev = 1;

        if (fieldNumber <= 1) {
            stepPrev = 0;
        }

        this.setState((state) => ({
            fieldNumber: state.fieldNumber - stepPrev
        }));
    }

    onNextHandler(evt) {
        evt.preventDefault();
        this.setState((state) => ({
            fieldNumber: state.fieldNumber + 1
        }));
    }

    handleChange(evt) {
        evt.preventDefault();
        let newNumber = +evt.target.value;

        if (newNumber <= 1) {
            newNumber = 1;
        }

        this.setState({
            fieldNumber: newNumber
        });
    }

    onSubmit(evt) {
        evt.preventDefault();
        const {goodsPageInfo, setBasketItems, setNotification} = this.props;
        const {fieldNumber, selectedColor} = this.state;

        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

        axios({
            method: 'post',
            url: `/api/add_basket_good`,
            data: {
                good_id: goodsPageInfo.id,
                color_id: selectedColor,
                count: fieldNumber

            },
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        })
            .then((res) => {
                setBasketItems(res.data);
                setNotification({
                    text: res.data.message,
                    status: res.data.status
                });
            })
            .catch((error) => {
                setNotification({
                    text: error.response.data.message,
                    status: error.response.data.status
                });
            })
    }

    changeColor(evt) {
        const {setCurrentMainImg, goodsPageInfo} = this.props;
        this.setState({ selectedColor: +evt.target.value });

        const {colors} = goodsPageInfo;

        const getColorById = (id, colors) => {
            return colors.filter((color) => color.id === id)[0];
        };

        const currentColor = getColorById(+evt.target.value, colors);

        setCurrentMainImg(currentColor);
    }

    componentDidMount() {
        const {goodsPageInfo, setCurrentMainImg} = this.props;
        const {colors} = goodsPageInfo;
        const {title, materials, dimensions} = goodsPageInfo;
        this.setState({ selectedColor: colors[0].id });
        setCurrentMainImg(colors[0]);
    }

    getNameColor(id, arrayColors) {
        if (id === null || arrayColors === null) {
            return null;
        }

        return arrayColors.filter((color) => color.id === id)[0].title;
    }

    render() {
        const {  goodsPageInfo, openFeedbackPopup} = this.props;
        const {colors} = goodsPageInfo;
        const {title, category, dimensions, materials, description, files} = goodsPageInfo;
        const {isShowTooltipe, fieldNumber, selectedColor} = this.state;

         const materialsArray =  (materials.length > 0) ? materials.map((material) => { return material.title }).join( ', ' ) : "не указаны";

        return (
            <div className="card-goods__content__inner">
                <CardInfo title={title} description={description} category={category} dimensions={dimensions}/>

                <form onSubmit={this.onSubmit} className="add-basket">
                    <div className="change-color__wrap">
                        <div className="change-color">
                            <span className="change-color__title">Выберите цвет: </span>
                            <div className="change-color__radio radio-color">
                                {colors.map((color, index) => {
                                    return (
                                        <div className="radio-color__wrap" key={color.id}>
                                            <input
                                                id={color.id}
                                                className="visually-hidden radio-color__input"
                                                type="radio"
                                                name="colors"
                                                value={color.id}
                                                checked={selectedColor === color.id}
                                                onChange={(e) => this.changeColor(e)}
                                            />
                                            <label htmlFor={color.id} className="radio-color__label" style={{backgroundColor: color.color}}/>
                                            <span itemProp="color" className="radio-color__tooltipe">{color.title}</span>

                                        </div>
                                    );
                                })}
                                {/*<b>{this.getNameColor(selectedColor, colors)}</b>*/}
                            </div>
                        </div>

                        <Tooltipe
                            isShow={isShowTooltipe}
                            // closeTooltipe={this.closeTooltipe}> Мы можем покрасить нашу мебель в любой необходимый Вам цвет, <a href="#" className="link" onClick={openFeedbackPopup}>свяжитесь с нами</a> и мы всё обсудим
                            closeTooltipe={this.closeTooltipe}> Мы можем покрасить мебель в любой цвет. <a href="#" className="link" onClick={openFeedbackPopup}>Свяжитесь с нами</a>, чтобы обсудить подробнее.
                        </Tooltipe>
                        <div
                            className="change-color__desc"
                            onClick={this.openTooltipe}
                        >
                            Нужен другой цвет?
                        </div>
                    </div>

                    {(materials.length > 0) && <div className="card-info__materials-wrap">
                        <span className="card-info__materials_title">Материалы: </span>
                        <span itemProp="material" className="card-info__material__names">{ materialsArray } </span>
                    </div>}

                    <div className="change-count">
                        <div className="change-count__title">Количество</div>
                        <div className="change-count__input input-count">
                            <button
                                className="input-count__btn"
                                onClick={this.onPrevHandler}
                            >
                                <svg width="15" height="2" viewBox="0 0 15 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="15" height="1.07143" fill="black"/>
                                </svg>
                            </button>
                            <input
                                value={+fieldNumber}
                                onChange={this.handleChange}
                                type="number"
                                className="input-count__field"
                                min="0"
                                step="1"
                            />
                            <button
                                className="input-count__btn"
                                onClick={this.onNextHandler}
                            >
                                <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect y="7" width="15" height="1.07143" fill="black"/>
                                    <rect x="8.03516" y="0.0351562" width="15" height="1.07143" transform="rotate(90 8.03516 0.0351562)" fill="black"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div className="add-basket__footer">
                        <button className="add-basket__submit btn" type="submit">Добавить в корзину</button>
                        <div className="add-basket__desc">Добавьте товар в корзину, чтобы отправить нам запрос стоимости</div>
                        <div className="add-basket__hidden" itemProp="aggregateRating" itemScope="" itemType="https://schema.org/AggregateRating">
                            Rated <span itemProp="ratingValue">5</span>/<span itemProp="reviewCount">10</span>
                        </div>
                    </div>
                </form>

                {(files.length > 0) && <div className="card-info__files-wrap">
                    {files.map((file, index) => {
                        return <a key={index} href={file.fileUrl} title={file.title} className="card-info__files__link" download>
                                <span className="card-info__files__name">{file.title}</span>
                                <span className="card-info__files__size">
                                    <b>{file.format}</b>
                                    <i>({file.size})</i>
                                </span>
                            </a>;
                    })}
                </div>}
            </div>
        );
    }
};

const mapDispatchToProps = (dispatch) => ({
    setBasketItems: (items) => dispatch(ActionCreator.setBasketItems(items)),
    setNotification: (options) => dispatch(ActionCreatorNotification.setNotification(options))
});

export default connect(null, mapDispatchToProps)(FormGoodsCard);
