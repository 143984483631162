const initialState = {
    text: ``,
    status: null
};

const ActionType = {
    SET_NOTIFICATION: `SET_NOTIFICATIONS`,
    CLEAR_NOTIFICATION: `CLEAR_NOTIFICATION`
};

const ActionCreator = {
    setNotification: (options) => ({
        type: ActionType.SET_NOTIFICATION,
        payload: options
    }),
    clearNotification: () => ({
        type: ActionType.CLEAR_NOTIFICATION
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_NOTIFICATION: return Object.assign({}, state, {
            text: action.payload.text,
            status: action.payload.status
        });
        case ActionType.CLEAR_NOTIFICATION: return Object.assign({}, state, {
            text: ``,
            status: null
        });
    }

    return state;
};

export {
    ActionCreator,
    reducer
}
