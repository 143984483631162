const initialState = {
    mainSlider: [],
    slides: [],
    projects: [],
    currentIndex: 1,
    seriesProject: [],
    homeProjects: [],
};

const ActionType = {
    GET_MAIN_SLIDER: `GET_MAIN_SLIDER`,
    SET_CURRENT_INDEX: `SET_CURRENT_INDEX`,
    SET_SLIDES: `SET_SLIDES`,
    SET_PROJECTS: `SET_PROJECTS`,
    SET_SERIES_PROJECT: `SET_SERIES_PROJECT`,
    SET_HOME_PROJECTS: `GET_HOME_PROJECTS`
};

const ActionCreator = {
    getMainSlider: (propjects) => ({
        type: ActionType.GET_MAIN_SLIDER,
        payload: propjects
    }),
    setSlides: (slides) => ({
        type: ActionType.SET_SLIDES,
        payload: slides
    }),
    setCurrentIndex: (currentIndex) => ({
        type: ActionType.SET_CURRENT_INDEX,
        payload: currentIndex
    }),
    setProjects: (projects) => ({
        type: ActionType.SET_PROJECTS,
        payload: projects,
    }),
    setSeriesProject: (projects) => ({
        type: ActionType.SET_SERIES_PROJECT,
        payload: projects
    }),
    setHomeProjects: (projects) => ({
        type: ActionType.SET_HOME_PROJECTS,
        payload: projects
    })
};

const Operations = {
    getMainSlider: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_sliders`)
            .then((response) => {
                dispatch(ActionCreator.getMainSlider(response.data));
            });
    },
    getProjects: () => (dispatch, _getState, api) => {
      return api.get(`/api/get_projects`)
          .then((response) => {
             dispatch(ActionCreator.setProjects(response.data));
          });
    },
    getSeriesProject: (slug) => (dispatch, _getState, api) => {
        return api.get(`/api/get_series_projects/${slug}`)
            .then((response) => {
                dispatch(ActionCreator.setSeriesProject(response.data))
            })
    },
    getHomeProjects: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_home_projects`)
            .then((response) => {
                dispatch(ActionCreator.setHomeProjects(response.data));
            });
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_MAIN_SLIDER: return Object.assign({}, state, {
            slides: action.payload
        });
        case ActionType.SET_SLIDES: return Object.assign({}, state, {
            slides: action.payload
        });
        case ActionType.SET_CURRENT_INDEX: return Object.assign({}, state, {
            currentIndex: action.payload
        });
        case ActionType.SET_PROJECTS: return Object.assign({}, state, {
            projects: action.payload
        });
        case ActionType.SET_SERIES_PROJECT: return Object.assign({}, state, {
            seriesProject: action.payload
        });
        case ActionType.SET_HOME_PROJECTS: return Object.assign({}, state, {
            homeProjects: action.payload
        });
    }

    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
};
