const initialState = {
    instagramMedia: []
};

const ActionType = {
    SET_INSTAGRAM_MEDIA: `SET_INSTAGRAM_MEDIA`,
};

const ActionCreator = {
    setInstagramMedia: (media) => ({
        type: ActionType.SET_INSTAGRAM_MEDIA,
        payload: media
    }),
};

const Operations = {
    getInstagramMedia: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_instagram_media`)
            .then((response) => {
                dispatch(ActionCreator.setInstagramMedia(response.data.data));
            })
            .catch((error) => {
                dispatch(ActionCreator.setInstagramMedia([]));
            })
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_INSTAGRAM_MEDIA: return Object.assign({}, state, {
            instagramMedia: action.payload
        });
    }
    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
};
