import React, {PureComponent} from 'react';
import axios from 'axios';

class GoodSlidesMobile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            currentSlide: 0
        };
        this.swiper = null;
        this.changeSlideHandler = this.changeSlideHandler.bind(this);
    }

    changeSlideHandler() {
        this.setState({
            currentSlide: this.swiper.realIndex
        });
    }

    async componentDidMount() {
        const response = await axios.get('/api/get_sliders');
        this.setState({
            slides: response.data
        });
        this.swiper = new Swiper('.swiper-good-slides-js',
            {
                loop: false,
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev'
            });
        const curSlider = this.swiper;
        let $radios = document.querySelectorAll(".radio-color__wrap input");
        Array.prototype.forEach.call( $radios, function(el, i) {
            el.onclick = function (e) {
                curSlider.slideTo(0, 600, console.log("show first slide"));
            };
        });

        this.swiper.on('slideChange', this.changeSlideHandler);
    }

    componentWillUnmount() {
        this.swiper = null;
    }

    render() {
        const {slides, mainimg} = this.props;
        const {currentSlide} = this.state;
        if (slides.length > 0) { return (
            <div className="custom-slider-mobile">
                <div className="swiper-container swiper-good-slides-js">
                    <div className="swiper-wrapper">
                        <div className="swiper-slide is--main-img"> <img src={mainimg} itemProp="image" className="card-goods__gallery_img-main"/> </div>
                        {slides.map((slide, index) => {
                            return <div className="swiper-slide" key={`slide-${index}`}>
                                    <img src={slide.imgUrl} alt={slide.alt}/>
                                </div>;
                        })}
                    </div>
                    <div className="swiper-button-next"></div>
                    <div className="swiper-button-prev"></div>
                </div>
            </div>);
        }
        else {
            return (<div className="card-goods__img-box">
                <img src={mainimg} itemProp="image" className="card-goods__gallery_img-main"/>
            </div> );
        }
    }
};

export default GoodSlidesMobile;
