const initialState = {
    isOpen: false
};

const ActionType = {
    OPEN_POPUP_MENU: `OPEN_POPUP_MENU`,
    CLOSE_POPUP_MENU: `CLOSE_POPUP_MENU`
};

const ActionCreator = {
    openPopup: () => ({
        type: ActionType.OPEN_POPUP_MENU,
        payload: true
    }),
    closePopup: () => ({
        type: ActionType.CLOSE_POPUP_MENU,
        payload: false
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.OPEN_POPUP_MENU: return Object.assign({}, state, {
            isOpen: action.payload
        });
        case ActionType.CLOSE_POPUP_MENU: return Object.assign({}, state, {
            isOpen: action.payload
        });
    }

    return state;
};

export {
    ActionCreator,
    reducer
}
