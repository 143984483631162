import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {ActionCreator, Operations} from "../../reducers/basket/basket";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import axios from "axios";
import {KeyCodes, Urls} from "../../utils";
import FeedbackForm from "./feedback-form";
import {ActionCreator as ActionCreatorNotification} from "../../reducers/notification/notification";

class PopupBasket extends PureComponent {
    constructor(props) {
        super(props);

        this.deleteHandlerItem = this.deleteHandlerItem.bind(this);
        this.toCatalog = this.toCatalog.bind(this);
        this.nextScreen = this.nextScreen.bind(this);
        this.onResult = this.onResult.bind(this);
        this.setFeedbackStatus = this.setFeedbackStatus.bind(this);
        this.state = {feedbackStatus: null};
    }

    componentDidMount() {
        const {getBasketItems} = this.props;
        getBasketItems();
    }

    componentWillReceiveProps(nextProps) {
        const {isOpen, closePopup} = this.props;

        const escapeListener = (ev) => {
            if(ev.keyCode === KeyCodes.ESCAPE) {
                closePopup();
            }
        };

        if (nextProps.isOpen !== isOpen) {
            if(nextProps.isOpen){
                document.body.style.overflow = 'hidden';
                document.addEventListener('keydown', escapeListener);
            } else {
                document.body.style.overflow = 'unset';
                document.removeEventListener('keydown', escapeListener);
            }
        }
    }

    deleteHandlerItem(evt, id) {
        const {setBasketItems, setNotification} = this.props;
        evt.preventDefault();

        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

        axios({
            method: 'post',
            url: `/api/remove_basket_good`,
            data: {
                id: id
            },
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        })
            .then((res) => setBasketItems(res.data))
            .catch((error) => {
                setNotification({
                    text: error.response.data.message,
                    status: error.response.data.status
                });
            })
    }

    toCatalog(evt) {
        evt.preventDefault();
        const {closePopup, history} = this.props;
        closePopup();
        document.body.style.overflow = 'unset';
        document.removeEventListener('keydown', closePopup);
        history.push(`/${Urls.CATALOG}`)
    }

    nextScreen(number) {
        const {setScreen} = this.props;
        setScreen(number);
    }

    onResult(data) {
        const {setBasketItems} = this.props;

        this.nextScreen(3);
        setBasketItems(data);
    }

    setFeedbackStatus(status) {
        this.setState({ feedbackStatus: status });
    }

    render() {
        const {isOpen, closePopup, basketItems, screen} = this.props;

        const emptyBasket = <div className="popup-note">В корзине пока ничего нет, выберите подходящую мебель <a onClick={this.toCatalog}>в&nbsp;каталоге</a></div>;
        const orderSent = <div className="popup-note">Запрос отправлен! <div className="light-text">Мы формируем вам коммерческое предложение</div></div>;


        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-popup`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {isOpen && <div className="popup">
                        <div className="popup__header">
                            <div className="popup__title">Ваш заказ</div>
                            <button onClick={closePopup} className="popup__close">
                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 30.45L16.6202 19.0187C18.8372 17.3719 18.8372 14.0781 16.6202 12.4312L1 1" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                    <path d="M38.79 1L23.069 12.4312C20.852 14.0781 20.852 17.3719 23.069 19.0187L38.6892 30.45" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className="popup__body">
                            {(basketItems.goods.length > 0) && (screen === 1) && <div className="basket-card">

                                {basketItems.goods.map((item, index) => {
                                    return (
                                        <div className="basket-card__item" key={`basket-${index}`}>
                                            <a href="#" className="basket-card__img">
                                                <img src={item.image} alt=""/>
                                            </a>
                                            <div className="basket-card__wrap">
                                                <div className="basket-card__title">{item.title}</div>
                                                <div className="basket-card__color">
                                                    <span style={{backgroundColor: item.colorCode}}></span>
                                                    <div>Цвет: {item.colorTitle}</div>
                                                </div>
                                                <div className="basket-card__count">Количество: {item.count} шт.</div>
                                            </div>
                                            <a onClick={(evt) => this.deleteHandlerItem(evt, item.id)} href="#" className="basket-card__close">
                                                <div>Удалить</div>
                                                <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M1 19L10.9417 12.0132C12.3528 11.0066 12.3528 8.99342 10.9417 7.98684L1 1" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                                    <path d="M26 1L16.0522 7.98684C14.6493 8.99342 14.6493 11.0066 16.0522 12.0132L25.9362 19" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                                </svg>
                                            </a>
                                        </div>
                                    );
                                })}

                            </div>}

                            {(screen === 2) && <div className="popup__content">
                                <div>
                                    <h3 className="popup__name">Запросить стоимость</h3>
                                    <div className="popup__description">
                                        Заполните поля и отправьте нам свои контакты,
                                        мы пришлём коммерческое предложение
                                    </div>
                                    <FeedbackForm
                                        url={`/api/create_order`}
                                        onResult={this.onResult}
                                        setFeedbackStatus={this.setFeedbackStatus}
                                    />
                                </div>
                                <p className="popup__footnote">* Поля отмеченный звездочкой обязательны для заполнения</p>
                            </div>}

                            {(screen === 3) && orderSent}

                            {/*{feedbackStatus === "success" && <div className="form-status">*/}
                                {/*<div className="form-status__header">Контакты отправлены!</div>*/}
                                {/*<span>Мы свяжемся с вами в ближайшее время</span>*/}
                            {/*</div>}*/}
                            {/*{feedbackStatus === "error" && <div className="form-status">*/}
                                {/*<div className="form-status__header">Произошла ошибка</div>*/}
                                {/*<span>Попробуйте повторить попытку позже</span>*/}
                            {/*</div>}*/}

                            {(basketItems.goods.length === 0) && (screen === 1) && emptyBasket}
                        </div>
                        {(basketItems.goods.length > 0) && (screen === 1) && (<div className="popup__footer">
                            <hr className="hr"/>
                            <button className="btn" onClick={() => this.nextScreen(2)}>Запросить стоимость</button>
                            <div className="popup__desc">Отправьте нам запрос и мы пришлём коммерческое предложение</div>
                        </div>)}

                    </div>}
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-overlay`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {isOpen && <div onClick={closePopup} className="overlay"></div>}
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => ({
    isOpen: state.basket.isOpen,
    basketItems: state.basket.basketItems,
    screen: state.basket.screen
});

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => dispatch(ActionCreator.closePopup()),
    getBasketItems: () => dispatch(Operations.getBasketItems()),
    deleteBasketItem: (id) => dispatch(Operations.deleteBasketItem(id)),
    setBasketItems: (items) => dispatch(ActionCreator.setBasketItems(items)),
    setScreen: (number) => dispatch(ActionCreator.setScreen(number)),
    setNotification: (options) => dispatch(ActionCreatorNotification.setNotification(options))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PopupBasket));
