import React from "react";
import axios from "axios";
import {connect} from 'react-redux';
import {ActionCreator} from "../../reducers/notification/notification";

class FeedbackForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.state = {isLoading: false};
    }

    handleSubmit(evt) {
        const {setFeedbackStatus, url, setNotification, onResult = (() => {})} = this.props;
        evt.preventDefault();
        evt.stopPropagation();

        this.setState({isLoading: true});

        const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
        axios({
            method: 'post',
            url: url,
            data: {
                name: event.target[0].value,
                email: event.target[1].value,
                phone: event.target[2].value,
                comment: event.target[3].value
            },
            headers: {
                'X-CSRF-TOKEN': csrfToken
            }
        })
            .then((response) => {
                onResult(response.data);
                setFeedbackStatus(response.data.status);
            })
            .catch((error) => {
                const message = error.response.data.message;
                setNotification({
                    text: message,
                    status: error.response.data.status
                });
                this.setState({ isLoading: false })
            });
    }

    render() {
        return(
            <form className="popup__form" method="POST" name="feedback_form" onSubmit={(evt) => this.handleSubmit(evt)}>
                <section className="form-inputs">
                    <div className="input-block">
                        <input className="form-input" type="text" name="name" required placeholder="Ваше имя"/>
                    </div>
                    <div className="input-block">
                        <input className="form-input" type="email" name="email" required pattern="[^@\s]+@[^@\s]+\.[^@\s]+" placeholder="Эл. почта"/>
                    </div>
                    <div className="input-block">
                        <input className="form-input" type="tel" name="phone" required placeholder="Телефон"/>
                    </div>
                    <div className="input-block">
                        <textarea className="form-textarea" name="comment" required placeholder="Комментарий"/>
                    </div>
                </section>
                <div className="submit-block">
                    <input className="add-basket__submit btn feedback-btn" type="submit" disabled={this.state.isLoading}
                        value={this.state.isLoading ? 'Загрузка...' : 'Отправить'}/>
                    <p className="personal-data-hint">
                        Нажимая на кнопку, вы даёте согласие на обработку ваших персональных данных и принимаете  <a href="/pages/license-agreement">Пользовательское соглашение</a>
                    </p>
                </div>
            </form>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    setNotification: (options) => dispatch(ActionCreator.setNotification(options))
});


export default connect(null, mapDispatchToProps)(FeedbackForm);
