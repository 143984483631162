import React from "react";
import { connect } from "react-redux";

const FooterContacts = ({ contacts }) => {
  return (
    <div className="footer__contacts">
      <div className="footer__tel_wrap">
        <div className="footer__tel_title">Телефон</div>
        <a href={`tel:${contacts.phone}`} className="footer__tel">
          {contacts.phone}
        </a>
        {contacts.phone_second && (
          <a href={`tel:${contacts.phone_second}`} className="footer__tel">
            {contacts.phone_second}
          </a>
        )}
      </div>
      <div className="footer__email_wrap">
        <a href={`mailto:${contacts.email}`} className="footer__email">
          {contacts.email}
        </a>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    contacts: state.general.contacts,
  };
};

export default connect(mapStateToProps)(FooterContacts);
