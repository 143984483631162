import React from 'react';
import FooterMenu from "./footer-menu";
import FooterSocials from "./footer-socials";
import FooterContacts from "./footer-contacts";

const Footer = () => {
    return(
        <footer className="footer">
            <div className="container">
                <div className="grid-container">
                    <div className="footer__top">
                        <nav className="footer-nav__top_left">
                            <FooterMenu/>
                        </nav>
                        <div className="footer-nav__top_right">
                            <FooterContacts />
                            <FooterSocials />
                        </div>
                    </div>
                </div>
                <div className="grid-container">
                    <div className="footer__bottom">
                        <div className="footer__text footer__bottom_left">
                            <span className="footer__text_copy">© 2024 URFO </span>
                            <span className="footer__text_low">Все права защищены</span>
                        </div>
                        <div className="footer__text footer__bottom_right">
                            <span>Сделано в&nbsp;</span>
                            <a href="http://proektmarketing.ru/" target="_blank" className="footer__proektmarketing">
                                Proektmarketing+1
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
};

export default Footer;
