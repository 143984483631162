import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {ActionCreator} from "../../reducers/notification/notification";

class Notification extends PureComponent {
    constructor(props) {
        super(props);
    }

    clearNotificationHandler() {
        const {clearNotification} = this.props;

        setTimeout(() => {
            clearNotification();
        }, 3000);
    }

    render() {
        const {text, status, clearNotification} = this.props;

        if (status !== null) {
            this.clearNotificationHandler();
        }

        return (
            <ReactCSSTransitionGroup
                transitionName="notification-animate"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}
            >

                {(status !== null) && <div className="notification">
                    {text}
                    <svg onClick={clearNotification} className="notification__svg" width="21" height="15" viewBox="0 0 21 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 14.5L8.42554 9.06579C9.47949 8.28289 9.47949 6.7171 8.42554 5.93421L1 0.5" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round"/>
                        <path d="M20.4797 0.5L13.0063 5.93421C11.9523 6.7171 11.9523 8.28289 13.0063 9.06579L20.4318 14.5" stroke="currentColor" strokeMiterlimit="10" strokeLinejoin="round"/>
                    </svg>
                </div>}
            </ReactCSSTransitionGroup>
        );
    }

};

const mapStateToProps = (state) => ({
    text: state.notification.text,
    status: state.notification.status
});

const mapDispatchToProps = (dispatch) => ({
    clearNotification: () => dispatch(ActionCreator.clearNotification())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
