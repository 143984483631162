import React from "react"
import { configureStore } from "../configure-store";
import { Provider } from 'react-redux';
import Index from './index';

const store = configureStore();

class App extends React.Component {
  render () {

    return (
        <Provider store={store}>
            <Index/>
        </Provider>
    );
  }
}

export default App