import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import AnimationBase from "./animation-base";

const Catalog = (props) => {
    const {data, catalogCategory, vertical = false, showLine = true} = props;

    return (
        <Fragment>
            <div className={`catalog${vertical ? ` catalog--vertical` : ``}`}>
                {catalogCategory && (
                    <AnimationBase classDiv="new_catalog__category">
                        {catalogCategory}
                    </AnimationBase>
                )}
            </div>
            <div className={`catalog${vertical ? ` catalog--vertical` : ``}`}>
                {data.map((good, index) => {
                    return <Link to={`/${good.model}/${good.slug}`}
                                 key={`good-${index}`}
                                 className="catalog__item"
                    >
                        <AnimationBase>
                            <div itemScope="" itemType="https://schema.org/ImageObject">
                                {good.imageUrlHover
                                    ?  <div className={`catalog__img with-hover`} >
                                        <span className={(good.nova && !vertical) ? 'catalog__label' : 'catalog__label_hidden'}>new</span>
                                        <div className='catalog__img_hover-wrapper'>
                                            <img itemProp="contentUrl" src={good.imageUrl} loading="lazy" alt={good.title} className='catalog__img_no-hover'/>
                                            <img src={good.imageUrlHover} loading="lazy" alt={good.title} className='catalog__img_is-hover'/>
                                        </div>
                                    </div>
                                    :  <div className={`catalog__img`} >
                                        <span className={(good.nova && !vertical) ? 'catalog__label' : 'catalog__label_hidden'}>new</span>
                                        <img itemProp="contentUrl" src={good.imageUrl} loading="lazy" alt={good.title} className='catalog__img_no-hover'/>
                                    </div>
                                }

                                <div itemProp="name" className="catalog__title">{good.title}</div>
                                <div itemProp="description" className="catalog__desc">{good.shortDescription}</div>
                            </div>
                        </AnimationBase>
                    </Link>;
                })}
            </div>
            { showLine ? <div className="new_catalog__category_hr"> </div> : "" }

        </Fragment>
    );
};

export default Catalog;
