const initialState = {
    goodsPageInfo: null
};

const ActionType = {
  SET_GOODS_PAGE_INFO: `SET_GOODS_PAGE_INFO`,
  CLEAR_GOODS_PAGE_INFO: `CLEAR_GOODS_PAGE_INFO`
};

const ActionCreator = {
    setGoodsPageInfo: (pageInfo) => ({
        type: ActionType.SET_GOODS_PAGE_INFO,
        payload: pageInfo
    }),
    clearGoodsPageInfo: () => ({
        type: ActionType.CLEAR_GOODS_PAGE_INFO,
        payload: null
    })
};

const Operations = {
    getGoodsPageInfo: (slug) => (dispatch, _getState, api) => {
        return api.get(`/api/get_good_info/${slug}`)
            .then((response) => dispatch(ActionCreator.setGoodsPageInfo(response.data)))
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_GOODS_PAGE_INFO: return Object.assign({}, state, {
            goodsPageInfo: action.payload
        });
        case ActionType.CLEAR_GOODS_PAGE_INFO: return Object.assign({}, state, {
            goodsPageInfo: action.payload
        });
    }

    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
}
