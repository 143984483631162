const initialState = {
    feedbackIsOpen: false,
    feedbackStatus: null
};

const ActionType = {
    OPEN_FEEDBACK_POPUP: `OPEN_FEEDBACK_POPUP`,
    CLOSE_FEEDBACK_POPUP: `CLOSE_FEEDBACK_POPUP`,
    SET_FEEDBACK_STATUS: `SET_FEEDBACK_STATUS`,
};

const ActionCreator = {
    openFeedbackPopup: () => ({
        type: ActionType.OPEN_FEEDBACK_POPUP,
        payload: true
    }),
    closeFeedbackPopup: () => ({
        type: ActionType.CLOSE_FEEDBACK_POPUP,
        payload: false
    }),
    setFeedbackStatus: (feedbackStatus) => ({
        type: ActionType.SET_FEEDBACK_STATUS,
        payload: feedbackStatus
    })
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.OPEN_FEEDBACK_POPUP: return Object.assign({}, state, {
            feedbackIsOpen: action.payload
        });
        case ActionType.CLOSE_FEEDBACK_POPUP: return Object.assign({}, state, {
            feedbackIsOpen: action.payload
        });
        case ActionType.SET_FEEDBACK_STATUS: return Object.assign({}, state, {
            feedbackStatus: action.payload
        });
    }

    return state;
};

export {
    ActionCreator,
    reducer
}
