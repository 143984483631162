import React from 'react';
import Header from "../items/header";
import Footer from "../items/footer";
import MainContent from "../items/main-content";
import {Link} from 'react-router-dom';
import {Urls} from "../../utils";

import promenade from '../../../assets/images/naberezhnaya-krsk.jpg';
import antSeria from '../../../assets/images/ant-seria.jpg';
import seedany from '../../../assets/images/seedany-seria.jpg';

import customization from '../../../assets/images/about-icons/customization-icon.svg';
import modernDesign from '../../../assets/images/about-icons/modern-design-icon.svg';
import natureMaterials from '../../../assets/images/about-icons/nature-materials-icon.svg';
import smartCost from '../../../assets/images/about-icons/smart-cost-icon.svg';
import warrantly from '../../../assets/images/about-icons/warranty-icon.svg';
import AnimationBase from "../items/animation-base";

const AboutPage = () => {
    return (
        <div className="green-theme about">
            <Header/>
            <MainContent>
                <div className="container grid-container">
                    <AnimationBase classDiv="h1-title about__header">
                        Мы придумываем и&nbsp;производим городскую мебель
                    </AnimationBase>
                </div>
                <div className="container grid-container about-container">
                    <AnimationBase classDiv="about__main-image">
                        <img src={promenade} alt="Набережная Красноярска"/>
                    </AnimationBase>
                </div>
                <div className="container grid-container">
                    <AnimationBase classDiv="h1-title about__aim-header">
                        Помогаем общественным пространствам быть в центре внимания
                    </AnimationBase>
                    <AnimationBase classDiv="two-blocks">
                        <h3 className="two-blocks__header">URFO — это …</h3>
                        <div className="two-blocks__items">
                            <div className="two-blocks__block">… авторский взгляд на то, какой должна быть современная
                                городская мебель в российском контексте. Наши решения соединяют тренды мировой урбанистики с практикой
                                российского благоустройства и традициями деревянного зодчества.
                            </div>
                            <div className="two-blocks__block">
                                … надежный партнер на всей дистанции проектной работы. Мы подключаемся ещё на эскизном этапе: берём на себя все вопросы по
                                разработке уличной мебели и делаем так, чтобы она точно
                                отвечала концепции благоустройства, поставленным задачам и возможностям конкретной локации.
                                А затем скрупулёзно воплощаем согласованные решения
                                в дереве и металле.
                            </div>
                        </div>
                    </AnimationBase>
                    <AnimationBase classDiv="portfolio">
                        <h3 className="portfolio__header">В нашем портфолио:</h3>
                        <ul>
                            <li className="portfolio__item">
                                <span className="accent-in-text">более</span>
                                <Link to={Urls.PROJECTS}> 50 объектов </Link>
                                <span className="accent-in-text">благоустройства</span>
                            </li>
                            <li className="portfolio__item">
                                <span className="accent-in-text">свыше</span>
                                <Link to={Urls.CATALOG}> 1000 единиц </Link>
                                <span className="accent-in-text">произведенной мебели</span>
                            </li>
                        </ul>
                    </AnimationBase>
                    <AnimationBase classDiv="advantages">
                        Мебель URFO усиливает ваш проект и не выходит за рамки согласованных бюджетов. Наше производство следует
                        за вашими идеями и не навязывает своих условий. Дизайн и качество
                        городской мебели URFO настолько высоки, что её внешний вид не теряет актуальности с годами.
                    </AnimationBase>

                    <section className="advantage-items">
                        <div className="advantage-item">
                            <div className="advantage-item__image">
                                <img width="350" height="350" src={customization} alt="Иконка кастомизация"/>
                            </div>
                            <h5 className="advantage-item__header">Кастомизация</h5>
                            <div className="advantage-item__description">
                                Наши модели оригинальны вплоть до полной эксклюзивности. По вашему заказу мы создадим изделие в
                                единственном экземпляре или разработаем уникальный дизайн мебельной группы для конкретного
                                общественного пространства.
                            </div>
                        </div>
                        <div className="advantage-item">
                            <div className="advantage-item__image">
                                <img width="350" height="350" src={modernDesign} alt="Современный дизайн"/>
                            </div>
                            <h5 className="advantage-item__header">Современный дизайн</h5>
                            <div className="advantage-item__description">
                                Такой городской мебели не найти в чужих каталогах. Наш авторский стиль уникален: он лаконичный, чистый и современный.
                            </div>
                        </div>
                        <div className="advantage-item">
                            <div className="advantage-item__image">
                                <img width="350" height="350" src={natureMaterials} alt="Натуральные материалы"/>
                            </div>
                            <h5 className="advantage-item__header">Натуральные материалы</h5>
                            <div className="advantage-item__description">
                                Только натуральные материалы высокого качества. Только натуральные клеи и экологически безопасные финишные покрытия.
                            </div>
                        </div>
                        <div className="advantage-item">
                            <div className="advantage-item__image">
                                <img width="350" height="350" src={warrantly} alt="Гарантия"/>
                            </div>
                            <h5 className="advantage-item__header">Гарантия</h5>
                            <div className="advantage-item__description">
                                Гарантийный срок эксплуатации изделий URFO —
                                12 месяцев. Эксплуатационный срок — от 3 до 10 лет, в зависимости от сечения древесины.
                            </div>
                        </div>
                        <div className="advantage-item">
                            <div className="advantage-item__image">
                                <img width="350" height="350" src={smartCost} alt="Разумная стоимость"/>
                            </div>
                            <h5 className="advantage-item__header">Разумная стоимость</h5>
                            <div className="advantage-item__description">
                                Мебель URFO чуть дороже массовых реплик или изделий из ПВХ. Но при долгом сроке эксплуатации
                                ежегодная стоимость владения нашей мебелью оказывается ощутимо ниже.
                            </div>
                        </div>
                    </section>
                    <section className="about-series">
                        <AnimationBase classDiv="about-series__item">
                            <img src={antSeria} alt="Мебель ant-seria"/>
                        </AnimationBase>
                        <AnimationBase classDiv="about-series__item">
                            <img src={seedany} alt="Мебель seedany"/>
                        </AnimationBase>
                    </section>
                    <AnimationBase classDiv="about-furniture">
                        Эстетика URFO ненавязчива. Наша мебель не бросается в глаза
                        и&nbsp;без остатка «растворяется» в среде. При этом каждый предмет остается фантастически функциональным.
                        Ценность такого дизайна становится особенно очевидной в момент соприкосновения
                        и&nbsp;взаимодействия с нашей мебелью. Любое кресло или скамейка URFO делает мир чуть доброжелательнее и комфортнее для человека.
                        Это и есть наше дизайнерское кредо.
                    </AnimationBase>
                </div>
            </MainContent>
            <Footer/>
        </div>
    );
};

export default AboutPage;
