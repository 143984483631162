import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {ActionCreator} from "../../reducers/feedback/feedback";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import FeedbackForm from "../items/feedback-form"
import {KeyCodes} from "../../utils";

class PopupFeedback extends PureComponent {
    constructor(props) {
        super(props);
        this.setFeedbackStatus = this.setFeedbackStatus.bind(this);
        this.state = {feedbackStatus: null};
    }

    componentWillReceiveProps(nextProps) {
        const {feedbackIsOpen, closeFeedbackPopup} = this.props;

        const escapeListener = (ev) => {
            if(ev.keyCode === KeyCodes.ESCAPE) {
                closeFeedbackPopup();
            }
        };

        if (nextProps.feedbackIsOpen !== feedbackIsOpen) {
            if(nextProps.feedbackIsOpen){
                document.body.style.overflow = 'hidden';
                document.addEventListener('keydown', escapeListener);
            } else {
                document.body.style.overflow = 'unset';
                document.removeEventListener('keydown', escapeListener);
            }
        }
    }

    setFeedbackStatus(status) {
        this.setState({ feedbackStatus: status });
    }

    render() {
        const {feedbackIsOpen, closeFeedbackPopup, contacts} = this.props;

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-popup`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {feedbackIsOpen && <div className="popup">
                        <div className="popup-close__header">
                            <button onClick={closeFeedbackPopup} className="popup__close">
                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 30.45L16.6202 19.0187C18.8372 17.3719 18.8372 14.0781 16.6202 12.4312L1 1" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                    <path d="M38.79 1L23.069 12.4312C20.852 14.0781 20.852 17.3719 23.069 19.0187L38.6892 30.45" stroke="black" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className="popup__body">
                            {!this.state.feedbackStatus && <div className="popup__content">
                                <div>
                                    <h3 className="popup__name">Свяжитесь с нами</h3>
                                    <div className="popup__description popup__description_limited">
                                        Заполните поля и отправьте нам свои контакты,
                                        либо свяжитесь с нами сами по почте <a href={`mailto:${contacts.email}`} className="link">{contacts.email}</a> или телефону <a href={`tel:${contacts.phone}`} className="link">{contacts.phone}</a>
                                    </div>
                                    <FeedbackForm url={`/api/send_feedback_request`} setFeedbackStatus={this.setFeedbackStatus}/>
                                </div>

                                <p className="popup__footnote">* Все поля обязательны для заполнения</p>
                            </div>}
                            {this.state.feedbackStatus === "success" && <div className="form-status">
                                <h3 className="form-status__header">Контакты отправлены!</h3>
                                <p className="form-status__desc">Мы свяжемся с вами в ближайшее время</p>
                            </div>}
                            {this.state.feedbackStatus === "error" && <div className="form-status">
                                <h3 className="form-status__header">Произошла ошибка</h3>
                                <p className="form-status__desc">Попробуйте повторить попытку позже</p>
                            </div>}
                        </div>

                    </div>}
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-overlay`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {feedbackIsOpen && <div onClick={closeFeedbackPopup} className="overlay"></div>}
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => ({
    feedbackIsOpen: state.feedback.feedbackIsOpen,
    contacts: state.general.contacts
});

const mapDispatchToProps = (dispatch) => ({
    closeFeedbackPopup: () => dispatch(ActionCreator.closeFeedbackPopup())
});

export default connect(mapStateToProps, mapDispatchToProps)(PopupFeedback);
