import React, {Fragment, PureComponent} from 'react';
import 'lightgallery.js/dist/js/lightgallery.min.js';
import axios from 'axios';
import AnimationBase from "./animation-base";

class VitrageContent extends React.PureComponent {
    constructor(props) {
        super(props);
        let swiper = null;
        this.state = {vitrageData: ''};
        this.lightGalleryRef = React.createRef();
    }

    componentDidMount() {
        const {url, getRelatedSeries, getNextProject} = this.props;

        axios.get(url)
            .then(res => {
                this.setState({vitrageData: res.data});

                // Append swiper script to vitrage data
                this.swiper = new Swiper('.swiper-container',
                    {
                        loop: true,
                        navigation: {
                            nextEl: '.btn-next', prevEl: '.btn-prev',
                        },
                        pagination: {
                            el: '.swiper-pagination-vitrage',
                            type: 'fraction',
                            renderFraction: function (currentClass, totalClass) {
                                const initialTitle = this.slides[this.realIndex + 1]
                                    .dataset.swiperTitle;

                                return '<span class="' + currentClass + '"></span>' +
                                    ' / ' +
                                    '<span class="slide-number ' + totalClass + '"></span>' +
                                    '<span class="slide-title">' + initialTitle + '</span>';
                            }

                        },
                        on: {
                            slideChange: function (evt) {
                                const currentSlide = this.slides[this.realIndex + 1];
                                currentSlide.closest('.swiper-container')
                                    .querySelector('.slide-title')
                                    .textContent = currentSlide.dataset.swiperTitle;
                            }
                        }

                    });

                getRelatedSeries();
                getNextProject();

                lightGallery(this.lightGalleryRef.current, {
                    selector: '.lightgallery',
                    speed: 400,
                    nextHtml: `<div class="lg-btn-next"></div>`,
                    prevHtml: `<div class="lg-btn-prev"></div>`,
                    download: false
                });

            })
            .catch(error => {
               console.log("error", error);
            });
    }

    componentWillUnmount() {
        this.el.innerHTML = "";
        this.swiper = null;
    }

    render() {
        return (
            <AnimationBase>
                <div className="vtrg-content" ref={this.lightGalleryRef}>
                    <span dangerouslySetInnerHTML={{ __html: this.state.vitrageData }} ref={el => this.el = el}/>
                </div>
            </AnimationBase>
        );
    };
};

export default VitrageContent;
