import React from 'react';
import {Link} from 'react-router-dom';

const NextCard = (props) => {
    const {data} = props;
    // if (!data) {
    //     return null;
    // }
    const {title, imageUrl, url, linkTitle} = data;

    return (
        <Link to={url} className={url.includes('projects') ? "next-card mrg-top-main-content" : "next-card"}>
            <div className="container grid-container">
                <div className="next-card__next">{linkTitle}</div>
                <div className="next-card__wrap">
                    {imageUrl && <div className="next-card__img">
                        <img src={imageUrl} alt={title}/>
                    </div>}
                    <div className="next-card__title">{title}</div>
                </div>
            </div>
        </Link>
    );
};

export default NextCard;
