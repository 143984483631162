export function extractionFullImg(slides, currentMainImgShow = "") {
  const fullImages = slides.map(slide => {
    return {
      src: slide.imgBigUrl
    }
  });

  if (currentMainImgShow.length > 0) {
    fullImages.unshift({ src: currentMainImgShow });
  }

  return fullImages;
};
