const initialState = {
    contacts: {},
    seo: {},
    footermenu: {}
};

const ActionType = {
    GET_CONTACTS: `GET_CONTACTS`,
    GET_SEO: `GET_SEO`,
    GET_FOOTERMENU: 'GET_FOOTERMENU',
};

const ActionCreator = {
    getContacts: (contacts) => ({
        type: ActionType.GET_CONTACTS,
        payload: contacts
    }),
    getSeo: (seo) => ({
        type: ActionType.GET_SEO,
        payload: seo
    }),
    getFootermenu: (footermenu) => ({
        type: ActionType.GET_FOOTERMENU,
        payload: footermenu
    }),
};

const Operations = {
    getContacts: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_contacts`)
            .then((response) => {
                dispatch(ActionCreator.getContacts(response.data));
            });
    },
    getSeo: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_seo`)
            .then((response) => {
                dispatch(ActionCreator.getSeo(response.data));
            });
    },
    getFootermenu: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_footer_menu`)
            .then((response) => {
                dispatch(ActionCreator.getFootermenu(response.data));
            });
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.GET_CONTACTS: return Object.assign({}, state, {
            contacts: action.payload
        });
        case ActionType.GET_SEO: return Object.assign({}, state, {
            seo: action.payload
        });
        case ActionType.GET_FOOTERMENU: return Object.assign({}, state, {
            footermenu: action.payload
        });
    }

    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
};
