const initialState = {
    isOpen: false,
    basketItems: [],
    screen: 1
};

const ActionType = {
    OPEN_POPUP: `OPEN_POPUP`,
    CLOSE_POPUP: `CLOSE_POPUP`,
    SET_BASKET_ITEMS: `SET_BASKET_ITEMS`,
    SET_SCREEN: `SET_SCREEN`
};

const ActionCreator = {
    openPopup: () => ({
        type: ActionType.OPEN_POPUP,
        payload: true
    }),
    closePopup: () => ({
        type: ActionType.CLOSE_POPUP,
        payload: false
    }),
    setBasketItems: (items) => ({
        type: ActionType.SET_BASKET_ITEMS,
        payload: items
    }),
    setScreen: (number) => ({
        type: ActionType.SET_SCREEN,
        payload: number
    })
};

const Operations = {
    getBasketItems: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_basket_info`)
            .then((response) => {
                dispatch(ActionCreator.setBasketItems(response.data));
            });
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.OPEN_POPUP: return Object.assign({}, state, {
            isOpen: action.payload
        });
        case ActionType.CLOSE_POPUP: return Object.assign({}, state, {
            isOpen: action.payload,
            screen: 1
        });
        case ActionType.SET_BASKET_ITEMS: return Object.assign({}, state, {
            basketItems: action.payload
        });
        case ActionType.SET_SCREEN: return Object.assign({}, state, {
            screen: action.payload
        });
    }

    return state;
};

export {
    ActionCreator,
    reducer,
    Operations
}
