import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/index';
import createAPI from "./api/api";

export const api = createAPI((...args) => store.dispatch(...args));

export const configureStore = () => {
    const store = createStore(
        rootReducer,
        composeWithDevTools(
            applyMiddleware(thunk.withExtraArgument(api))
        )
    );
    return store;
};
