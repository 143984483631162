import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const AnimationRight = ({children, classDiv = ''}) => {
    return <ScrollAnimation
        animateIn='customAnimationRight'
        animateOnce={true}
        duration={0.5}
        className={classDiv}
    >
        {children}
    </ScrollAnimation>;
};

export default AnimationRight;
