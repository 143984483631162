import {getAdaptedGoods} from "../../api/adapter-goods";

const initialState = {
    goodsCatalog: [],
    categoriesCatalog: []
};

const ActionType = {
    GET_GOODS_CATALOG: `GET_GOODS_CATALOG`,
    SET_GOODS_CATALOG: `SET_GOODS_CATALOG`,
    GET_CATEGORIES_CATALOG: `GET_CATEGORIES_CATALOG`,
    SET_CATEGORIES_CATALOG: `SET_CATEGORIES_CATALOG`
};

const ActionCreator = {
    setGoodsCatalog: (goods) => ({
        type: ActionType.SET_GOODS_CATALOG,
        payload: goods
    }),
    setCategoriesCatalog: (categories) => ({
        type: ActionType.SET_CATEGORIES_CATALOG,
        payload: categories
    })
};

const Operations = {
    getGoodsCatalog: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_catalog_items`)
            .then((response) => {
                dispatch(ActionCreator.setGoodsCatalog(response.data));
            });
    },
    getCategoriesCatalog: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_catalog_categories`)
            .then((response) => {
                dispatch(ActionCreator.setCategoriesCatalog(response.data));
            });
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_GOODS_CATALOG: return Object.assign({}, state, {
            goodsCatalog: action.payload
        });
        case ActionType.SET_CATEGORIES_CATALOG: return Object.assign({}, state, {
            categoriesCatalog: action.payload
        });
    }

    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
}
