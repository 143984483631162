import React from 'react';
import {Link} from 'react-router-dom';
import {Urls} from "../../utils";
import AnimationBase from "./animation-base";

const ProjectsList = (props) => {
    const {projects} = props;

    const manyProjects = projects.length > 1;
    const borderItem = `project__item`;

    return (
        <section className={`projects`}>
            {projects.map((project, index) => {
                return <AnimationBase
                    key={`project-${index}`}
                    classDiv={borderItem}
                >
                    <Link
                        to={`/${Urls.PROJECTS}/${project.slug.toLowerCase()}`}

                    >

                        <div className="project__img">
                            <img src={project.imageUrl} alt={project.title}/>
                        </div>
                        <div className="project__title">
                            <span dangerouslySetInnerHTML={{ __html: project.title }} />
                        </div>
                        <div className="project__description">{project.description}</div>
                    </Link>
                </AnimationBase>
            })}
        </section>
    );
};

export default ProjectsList;
