import React, {Fragment, useEffect} from 'react';
import {NavLink as Link, useLocation} from "react-router-dom";
import {Urls} from "../../utils";

const Menu = (props) => {
    const {closePopup = (() => {}), isActive} = props;
    const fixClassActive = isActive ? ' active' : '';

    return (
        <Fragment>
            <Link onClick={closePopup} to={`/${Urls.ABOUT}`} activeClassName='active' className={`nav__link`}>О нас</Link>
            <Link onClick={closePopup} to={`/${Urls.CATALOG}`} activeClassName='active' className={`nav__link${fixClassActive}`}>Каталог</Link>
            <Link onClick={closePopup} to={`/${Urls.PROJECTS}`} activeClassName='active' className={`nav__link`}>Проекты</Link>
            {/*<Link onClick={closePopup} to={`/${Urls.NEWS}`} activeClassName='active' className={`nav__link`}>Новости</Link>*/}
        </Fragment>
    );
};

export default Menu;
