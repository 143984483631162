import React, { Fragment, PureComponent} from 'react';
import Header from "../items/header";
import Footer from "../items/footer";
import MainContent from "../items/main-content";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Operations} from "../../reducers/catalog/catalog";
import Catalog from "../items/catalog";
import CatalogCategories from "../items/catalog-categories";
import {Urls} from "../../utils";
import AnimationBase from "../items/animation-base";
import InstaItem from "../items/insta-item";
import {Operations as InstaOperations} from "../../reducers/instagram/instagram";
import Breadcrumbs from '../items/breadcrumbs';

class CatalogPage extends PureComponent {
    state = {
        currentCategoryTitle: "Вся продукция",
        currentCategorySlug:  window.location.href.split('#')[1]
    };

    handleCategoryChange = this.handleCategoryChange.bind(this);

    constructor(props) {
        super(props);
    }
    handleCategoryChange(title) {
         this.setState({currentCategoryTitle:title});
    }
    componentDidMount() {
        const {getGoodsCatalog, getCategoriesCatalog, getInstagramMedia} = this.props;
        getGoodsCatalog();
        getCategoriesCatalog();
        getInstagramMedia();
    }
    componentDidUpdate(prevProps, prevState) {
        const anchor = window.location.href.split('#')[1];
        if ((anchor) && (prevProps.categoriesCatalog.length > 0)) {
            let anchorTitle = prevProps.categoriesCatalog.filter((cat) => cat.slug === anchor).map((currentCat) => currentCat.title);
            if ( prevState.currentCategoryTitle !== anchorTitle[0] ) {
                this.handleCategoryChange(anchorTitle[0]);
            }
        }
    }

    render() {
        const {goodsCatalog} = this.props;
        const {categoriesCatalog} = this.props;
        const {instagramMedia, contacts} = this.props;
        const {currentCategoryTitle} = this.state;
        const {currentCategorySlug} = this.state;

        if (!goodsCatalog) {
            return (
                <Fragment>
                    <Header/>
                    <MainContent>
                        <Breadcrumbs items={ [ { title:"Urfo", url:"/" } ]} activeItem={{ title:"Каталог", url: Urls.CATALOG  }} />
                        <div className="container grid-container">
                            <h1 className="h1-title">
                                <AnimationBase>Каталог</AnimationBase>
                            </h1>
                        </div>
                    </MainContent>
                    <Footer/>
                </Fragment>
            );
        }
        if ((currentCategoryTitle) && (currentCategoryTitle!=="Вся продукция") ) {
            return (
                <Fragment>
                    <Header/>
                    <MainContent>
                        <Breadcrumbs items={ [ { title:"Urfo", url:"/" }, { title:"Каталог", url: Urls.CATALOG } ]} activeItem={{ title:currentCategoryTitle, url: Urls.CATALOG + "#" + currentCategorySlug } } />
                        <div className="container grid-container">
                            <CatalogCategories categories={categoriesCatalog} currentCategoryTitle={currentCategoryTitle} onCurrentCategoryChange={this.handleCategoryChange}  />
                            <Catalog  data={goodsCatalog.filter((goodsItem) => goodsItem.category === currentCategoryTitle )} />
                            {instagramMedia.length > 0 && <InstaItem instagramMedia={instagramMedia} contacts={contacts}></InstaItem>}
                        </div>
                    </MainContent>
                    <Footer/>
                </Fragment>
            );
        }
        else {
            return (
                <Fragment>
                    <Header/>
                    <MainContent>
                        <Breadcrumbs items={ [ { title:"Urfo", url:"/" } ]} activeItem={ { title:"Каталог", url: Urls.CATALOG } } />
                        <div className="container grid-container">
                            <CatalogCategories categories={categoriesCatalog} currentCategoryTitle={currentCategoryTitle} onCurrentCategoryChange={this.handleCategoryChange}  />
                            {categoriesCatalog.map((category, index) => {
                                return (
                                    <Catalog key={`category-${index}`} data={goodsCatalog.filter((goodsItem) => goodsItem.category === category.title)} catalogCategory={category.title}/>
                                )
                            })}
                            {instagramMedia.length > 0 && <InstaItem instagramMedia={instagramMedia} contacts={contacts}></InstaItem>}
                        </div>
                    </MainContent>
                    <Footer/>
                </Fragment>
            );
        }


    }
};

const mapStateToProps = (state) => {
    return {
        goodsCatalog: state.catalog.goodsCatalog,
        categoriesCatalog: state.catalog.categoriesCatalog,
        instagramMedia: state.instagram.instagramMedia,
        contacts: state.general.contacts
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getGoodsCatalog: bindActionCreators(Operations.getGoodsCatalog, dispatch),
        getCategoriesCatalog: bindActionCreators(Operations.getCategoriesCatalog, dispatch),
        getInstagramMedia: bindActionCreators(InstaOperations.getInstagramMedia, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage);
