import React, { Fragment } from "react";
import Header from "../items/header";
import Footer from "../items/footer";
import MainContent from "../items/main-content";
import { Urls } from "../../utils";
import { connect } from "react-redux";
import AnimationBase from "../items/animation-base";

class ContactsPage extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { contacts } = this.props;

    return (
      <Fragment>
        <Header />

        <MainContent>
          <div className="container short-grid-container">
            <h1 className="h1-title">
              <AnimationBase>Контакты</AnimationBase>
            </h1>

            {contacts.address && (
              <AnimationBase classDiv="contacts">
                <div className="contacts__header">Адрес:</div>

                <ul className="contacts__list">
                  <li>
                    <address className="contacts__address">
                      <span className="contacts__info">
                        <span
                          dangerouslySetInnerHTML={{ __html: contacts.address }}
                        />
                      </span>

                      <span className="contacts__info">
                        г.&nbsp;{contacts.city}
                      </span>
                    </address>
                  </li>

                  <li>
                    <address className="contacts__address">
                      <span className="contacts__info">
                        <span
                            dangerouslySetInnerHTML={{ __html: contacts.address_second }}
                        />

                      </span>
                      <span className="contacts__info">
                        г.&nbsp;{contacts.city_second}
                      </span>
                    </address>
                  </li>
                </ul>
              </AnimationBase>
            )}

            {contacts.phone && <AnimationBase classDiv="contacts">
              <div className="contacts__header">Контактный телефон:</div>

              <div className="contacts__info contacts__phone">
                <a href={`tel:${contacts.phone}`} className="link">
                  {contacts.phone}
                </a>
                {contacts.phone_second && (
                  <a href={`tel:${contacts.phone_second}`} className="link">
                    {contacts.phone_second}
                  </a>
                )}
              </div>
            </AnimationBase>}

            <AnimationBase classDiv="networks">
              <div className="networks__links">
                <a
                  href={`mailto:${contacts.email}`}
                  className="networks__link networks__email"
                >
                  {contacts.email}
                </a>
              </div>
              <div className="networks__links networks__socials">
                {contacts.instaLink && (
                  <a
                    href={contacts.instaLink}
                    className="networks__link"
                    target="_blank"
                  >
                    Instagram
                  </a>
                )}
                {contacts.facebookLink && (
                  <a
                    href={contacts.facebookLink}
                    className="networks__link"
                    target="_blank"
                  >
                    Facebook
                  </a>
                )}
              </div>
            </AnimationBase>
          </div>
        </MainContent>
        <Footer />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.general.contacts,
  };
};

export default connect(mapStateToProps, null)(ContactsPage);
