import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ActionCreator} from "../../reducers/basket/basket";

const Basket = () => {
    const basketItems = useSelector(state => state.basket.basketItems);
    const dispatch = useDispatch();

    return (
        <button onClick={() => dispatch(ActionCreator.openPopup())} className="basket btn">
            <span>Корзина</span>
            <span className="basket__count"> ({basketItems?.goodsCount ? basketItems.goodsCount : 0})</span>
        </button>
    );
};

export default Basket;

