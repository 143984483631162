import React, {PureComponent, Fragment} from 'react';
import {connect} from 'react-redux';
import {NavLink as Link, withRouter} from 'react-router-dom';
import {ActionCreator} from "../../reducers/popup-menu/menu";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {KeyCodes, Urls} from "../../utils";
import Menu from "./menu";
import Socials from "./socials";
import FooterContacts from "./footer-contacts";

class PopupMenu extends PureComponent {
    constructor(props) {
        super(props);
    }

    escapeListener = (ev) => {
        const {closePopup} = this.props;
        if(ev.keyCode === KeyCodes.ESCAPE) {
            closePopup();
        }
    };

    componentWillReceiveProps(nextProps) {
        const {isOpen, closePopup} = this.props;

        if (nextProps.isOpen !== isOpen) {
            if(nextProps.isOpen){
                document.body.style.overflow = 'hidden';
                document.addEventListener('keydown', this.escapeListener);
            } else {
                document.body.style.overflow = 'unset';
                document.removeEventListener('keydown', this.escapeListener);
            }
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        document.removeEventListener('keydown', this.escapeListener);
    }

    render() {
        const {isOpen, closePopup} = this.props;

        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-popup`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {isOpen && <div className="popup popup-menu">
                        <div className="popup__header">
                            <div className="popup__title">
                                <svg className="popup__logo" width="189" height="53" viewBox="0 0 189 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M56.1365 44.4318H9.02254V16.2109H0V45.619C0 50.0285 2.84922 52.8438 7.22481 52.8438H57.9003C62.3098 52.8438 65.1251 49.9946 65.1251 45.619V16.2109H56.1025V44.4318H56.1365Z" fill="currentColor"/>
                                    <path d="M181.775 16.2109H129.811C125.401 16.2109 122.586 19.0602 122.586 23.4358V45.619C122.586 50.0285 125.435 52.8438 129.811 52.8438H181.775C186.185 52.8438 189 49.9946 189 45.619V23.4358C189 19.0262 186.151 16.2109 181.775 16.2109ZM179.977 44.4318H131.608V24.6229H179.977V44.4318Z" fill="currentColor"/>
                                    <path d="M74.1816 23.4358V52.8438H83.2042V24.6229H92.2267V16.2109H81.4065C76.9969 16.2109 74.1816 19.0262 74.1816 23.4358Z" fill="currentColor"/>
                                    <path d="M98.5371 7.22481V52.8463H107.56V24.6254H116.582V16.2134H107.56V8.44591H118.753V0H105.762C101.386 0 98.5371 2.84922 98.5371 7.22481Z" fill="currentColor"/>
                                </svg>
                            </div>
                            <button onClick={closePopup} className="popup__close">
                                <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 30.45L16.6202 19.0187C18.8372 17.3719 18.8372 14.0781 16.6202 12.4312L1 1" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                    <path d="M38.79 1L23.069 12.4312C20.852 14.0781 20.852 17.3719 23.069 19.0187L38.6892 30.45" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>
                        <div className="popup__body">
                            <div className="popup__menu">
                                <Menu closePopup={closePopup}/>
                                <Link style={{marginTop: '5.6rem'}} onClick={closePopup} to={`/${Urls.SEARCH}`} className={`nav__link`}>Поиск</Link>
                            </div>
                        </div>
                        <div className="popup__footer">
                            <hr className="hr"/>
                            <div className="socials">
                                <Socials />
                            </div>
                            <FooterContacts />
                        </div>
                    </div>}
                </ReactCSSTransitionGroup>
                <ReactCSSTransitionGroup
                    component={Fragment}
                    transitionName={`animation-overlay`}
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={500}
                >
                    {isOpen && <div onClick={closePopup} className="overlay"></div>}
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => ({
    isOpen: state.menu.isOpen
});

const mapDispatchToProps = (dispatch) => ({
    closePopup: () => dispatch(ActionCreator.closePopup())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PopupMenu));
