import React, { useState } from "react";
import ImgsViewer from "../../libs/react-images-viewer";

const GoodSlides = ({ slides, mainimg, fullImages }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currImg, setCurrImg] = useState(0);

  const imageWrapClassNames =
    slides.length > 0 ? "card-goods__gallery_lnk is--main-img" : "";
  const galleryClassNames =
    slides.length > 0 ? "card-goods__gallery" : "card-goods__img-box";

  const openImage = (index) => {
    setIsOpen(true);
    setCurrImg(index);
  };

  const handleClickNext = () => {
    setCurrImg((prevImg) => prevImg + 1);
  };

  const handleClickPrev = () => {
    setCurrImg((prevImg) => prevImg - 1);
  };

  const handleCloseView = () => {
    setIsOpen(false);
  };

  return (
    <div className={galleryClassNames}>
      <ImgsViewer
        imgs={fullImages}
        width={1920}
        showImgCount={false}
        spinnerDisabled={true}
        closeBtnTitle="Закрыть изображение"
        leftArrowTitle="Предыдущее изображение"
        rightArrowTitle="Следующее изображение"
        backdropCloseable={true}
        currImg={currImg}
        isOpen={isOpen}
        onClickPrev={handleClickPrev}
        onClickNext={handleClickNext}
        onClose={handleCloseView}
      />

      <div onClick={() => openImage(0)} className={imageWrapClassNames}>
        <img
          itemProp="image"
          src={mainimg}
          className="card-goods__gallery_img-main"
        />
      </div>

      {slides.length > 0 &&
        slides.map((slide, index) => {
          return (
            <div
              onClick={() => openImage(index + 1)}
              className="card-goods__gallery_lnk"
              key={`good-slide-${index}`}
            >
              <img
                src={slide.imgUrl}
                alt={slide.alt}
                className="card-goods__gallery_img"
              />
            </div>
          );
        })}
    </div>
  );
};

export default GoodSlides;
