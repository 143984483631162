import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import "animate.css/animate.min.css";

const AnimationBase = ({children, classDiv = ''}) => {
    return <ScrollAnimation
        animateIn='customBaseAnimation'
        animateOnce={true}
        duration={0.5}
        className={classDiv}
    >
        {children}
    </ScrollAnimation>;
};

export default AnimationBase;
