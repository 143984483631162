import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';

import AnimationBase from "../items/animation-base";
import Header from "../items/header";
import MainContent from "../items/main-content";

class SearchPage extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            catalogItems: [],
            textInput: '',
            isResultsShow: false
        };

        this.textInput = React.createRef();
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    componentDidMount() {
        axios.get(`/api/get_catalog_items`)
            .then(({data}) => this.setState({catalogItems: data}))
            .catch((err) => console.log(err.message));
        this.textInput.current.focus();
    }

    onChangeHandler(evt) {
        const {isResultsShow} = this.state;

        if (!isResultsShow) {
            this.setState({
                isResultsShow: true
            });
        }

        this.setState({
            textInput: evt.target.value
        });
    }

    render() {
        const {catalogItems, textInput, isResultsShow} = this.state;

        const filterCatalogItemsFn = (item) => {
            return item.title.toLowerCase().includes(textInput.toLowerCase());
        };

        const accentWordTitleFn = (string) => {
            const positionSubstr = string.toLowerCase().indexOf(textInput.toLowerCase());

            if (positionSubstr === -1) {
                return string;
            }

            return {__html: `${string.slice(0, positionSubstr)}<span style="color: #FDB913;">${string.slice(positionSubstr, positionSubstr + textInput.length)}</span>${string.slice(positionSubstr + textInput.length)}`};
        };

        return (
            <div className={`search-page${isResultsShow ? `` : ` bg-grey`}`}>
                <div className="search-page__header">
                    <Header/>

                        <div className="container grid-container">
                            <input
                                type="text"
                                className="search-page__input"
                                value={textInput}
                                placeholder="Поиск"
                                onChange={this.onChangeHandler}
                                ref={this.textInput}
                            />
                        </div>
                </div>
                    <MainContent>
                        {isResultsShow && <div className="container grid-container search-page__result">
                                            <h2 className="search-page__result-title">Результаты поиска</h2>
                                            {textInput.length > 0 && catalogItems.filter(filterCatalogItemsFn).length > 0 && <div className="search-page__title">Каталог</div>
                                            }

                                            <div className="search-page__catalog">
                                                {textInput.length > 0 && catalogItems.filter(filterCatalogItemsFn).map((good, index) => {
                                                    return <AnimationBase
                                                        key={`good-${index}`}
                                                        classDiv="catalog__item"
                                                    >

                                                        <Link to={`/${good.model}/${good.slug}`} className="catalog__img">
                                                            <span className={(good.nova) ? 'catalog__label' : 'catalog__label_hidden'}>new</span>
                                                            <img src={good.imageUrl} alt={good.title}/>
                                                        </Link>
                                                        <Link to={`/${good.model}/${good.slug}`} className="catalog__title">
                                                            <div dangerouslySetInnerHTML={accentWordTitleFn(good.title)} />
                                                            {/*{accentWordTitleFn(good.title)}*/}
                                                        </Link>
                                                        <div className="catalog__desc">{good.shortDescription}</div>

                                                    </AnimationBase>;
                                                })}
                                                {catalogItems.filter(filterCatalogItemsFn).length === 0 && <div>Поиск не дал результата.</div>}
                                                {textInput.length === 0 && <div>Начните вводить название товара</div>}
                                            </div>
                                        </div>}
                    </MainContent>
            </div>
        );
    }
};

export default SearchPage;
