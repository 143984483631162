const INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER = 60000;

const Urls = {
    SERIES: `series`,
    PROJECTS: `projects`,
    GOODS: `goods`,
    CONTACTS: `contacts`,
    ABOUT: `about`,
    CATALOG: `catalog`,
    SEARCH: `search`,
    PAGES: `pages`,
    NEWS: `news`,
    DELIVERY: `pages/delivery`
};

const KeyCodes = {
    ESCAPE: 27,
};

export {
    INTERVAL_CHANGE_SLIDE_IN_MAIN_SLIDER,
    Urls,
    KeyCodes
};
