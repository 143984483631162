import React from 'react';
import {Link} from 'react-router-dom';
import Menu from "./menu";
import Basket from "./basket";
import {Urls} from "../../utils";
import MenuMobile from "./menu-mobile";

const Header = ({isActive = false}) => {

    return (
        <header className="header container grid-container">
            <Link to="/" className="header__logo">
                <svg width="189" height="53" viewBox="0 0 189 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M56.1365 44.4318H9.02254V16.2109H0V45.619C0 50.0285 2.84922 52.8438 7.22481 52.8438H57.9003C62.3098 52.8438 65.1251 49.9946 65.1251 45.619V16.2109H56.1025V44.4318H56.1365Z" fill="currentColor"/>
                    <path d="M181.775 16.2109H129.811C125.401 16.2109 122.586 19.0602 122.586 23.4358V45.619C122.586 50.0285 125.435 52.8438 129.811 52.8438H181.775C186.185 52.8438 189 49.9946 189 45.619V23.4358C189 19.0262 186.151 16.2109 181.775 16.2109ZM179.977 44.4318H131.608V24.6229H179.977V44.4318Z" fill="currentColor"/>
                    <path d="M74.1816 23.4358V52.8438H83.2042V24.6229H92.2267V16.2109H81.4065C76.9969 16.2109 74.1816 19.0262 74.1816 23.4358Z" fill="currentColor"/>
                    <path d="M98.5371 7.22481V52.8463H107.56V24.6254H116.582V16.2134H107.56V8.44591H118.753V0H105.762C101.386 0 98.5371 2.84922 98.5371 7.22481Z" fill="currentColor"/>
                </svg>
            </Link>
            <nav className="nav header__nav">
                <Menu isActive={isActive}/>
            </nav>
            <ul className="header__tools">
                <li className="header__tool header__tool--search">
                    <Link to={`/${Urls.SEARCH}`} className="search-link"></Link>
                </li>
                <li className="header__tool header__tool--menu">
                    <MenuMobile />
                </li>
                <li className="header__tool">
                    <Basket />
                </li>
            </ul>
        </header>
    );
};

export default Header;
