import React from "react";
import ScrollUpButton from "react-scroll-up-button";

const ScrollUp = () => {
    return (
        <ScrollUpButton
            ContainerClassName="btn-up"
            TransitionClassName="btn-up__transition"
            ShowAtPosition={400}
        >
            <svg width="32" height="106" viewBox="0 0 32 106" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M31.2008 18.1998L19.4008 2.6998C17.7008 0.499805 14.3008 0.499805 12.6008 2.6998L0.800781 18.1998" stroke="#F6B41E" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
                <path d="M16 12L16 105" stroke="#F6B41E" strokeWidth="2" strokeMiterlimit="10" strokeLinejoin="round"/>
            </svg>
        </ScrollUpButton>
    )
};
export default ScrollUp
