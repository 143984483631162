import React from 'react';
import {connect} from 'react-redux';
import {ActionCreator} from "../../reducers/popup-menu/menu";

const MenuMobile = (props) => {
    const {openPopup} = props;

    return <div onClick={openPopup} className="btn btn--gray">Меню</div>;
};

const mapDispatchToProps = (dispatch) => ({
    openPopup: () => dispatch(ActionCreator.openPopup())
});

export default connect(null, mapDispatchToProps)(MenuMobile);

