import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {NavLink as Link, useLocation} from "react-router-dom";
import {Urls} from "../../utils";

const FooterMenu = ({footermenu}, props) => {
    const {closePopup = (() => {}), isActive} = props;
    const fixClassActive = isActive ? ' active' : '';
    return (
        <Fragment>

            {footermenu.pages && <div className={`footer-nav__link_wrap`}>
                <Link onClick={closePopup} to={`/${Urls.ABOUT}`} activeClassName='active' className={`footer-nav__link`}>О нас</Link>
                {/*{*/}
                {/*    footermenu.pages.map((page, index) => {*/}
                {/*        return <Link onClick={closePopup}  key={`footer-nav-link-page-${index}`} to={page.url} activeClassName='active' className={`footer-nav__link_small`}>{page.title}</Link>;*/}
                {/*    })*/}
                {/*}*/}
                <Link onClick={closePopup} to={`/${Urls.PROJECTS}`} activeClassName='active' className={`footer-nav__link`}>Проекты</Link>
                {/*<Link onClick={closePopup} to={`/${Urls.NEWS}`} activeClassName='active' className={`footer-nav__link`}>Новости</Link>*/}
            </div>}

            {footermenu.catalog && <div className={`footer-nav__link_wrap`}>
                <Link onClick={closePopup} to={`/${Urls.CATALOG}`} activeClassName='active' className={`footer-nav__link${fixClassActive}`}>Каталог</Link>
                {
                    footermenu.catalog.map((cat_link, index) => {
                        return <Link onClick={closePopup}  key={`footer-nav-link-cat-${index}`} to={cat_link.url} activeClassName='active' className={`footer-nav__link_small`}>{cat_link.title}</Link>;
                    })
                }
            </div>}

            <div className={`footer-nav__link_wrap`}>
                <Link onClick={closePopup} to={`/${Urls.CONTACTS}`} activeClassName='active' className={`footer-nav__link`}>Контакты</Link>
                <Link onClick={closePopup} to={`/${Urls.DELIVERY}`} activeClassName='active' className={`footer-nav__link`}>Доставка</Link>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        footermenu: state.general.footermenu
    }
};

export default connect(mapStateToProps)(FooterMenu);
