import React, {Fragment, PureComponent} from 'react';
import Header from "../items/header";
import Footer from "../items/footer";
import MainContent from "../items/main-content";
import VitrageContent from "../items/vitrage-content";
import Catalog from "../items/catalog";
import axios from "axios/index";
import {Helmet} from "react-helmet"
import NextCard from "../items/next-card";
import Breadcrumbs from '../items/breadcrumbs';

class ProjectPage extends PureComponent {
    constructor(props) {
        super(props);
        this.getRelatedSeries = this.getRelatedSeries.bind(this);
        this.getSeo = this.getSeo.bind(this);
        this.getProjectBreadcrumbs = this.getProjectBreadcrumbs.bind(this);
        this.getNextProject = this.getNextProject.bind(this);
        this.state = {relatedSeries: [], seo: {}, nextProject: {}, breadcrumbCurrent: {}, breadcrumbItems: {}};
    }

    componentDidMount() {
        this.getSeo();
        this.getProjectBreadcrumbs();
    }

    getRelatedSeries() {
        const {slug} = this.props;

        axios.get(`/api/get_project_series/${slug}`)
            .then((res) => {
            this.setState({relatedSeries: res.data});
        });
    }

    getNextProject() {
        const {slug} = this.props;

        axios.get(`/api/get_next_project/${slug}`)
            .then((res) => {
                this.setState({nextProject: res.data});
            });
    }

    getSeo() {
        const {slug} = this.props;

        axios.get(`/api/get_project_seo/${slug}`)
            .then((res) => {
                this.setState({seo: res.data});
            });
    }

    getProjectBreadcrumbs() {
        const {slug} = this.props;

        axios.get(`/api/get_project_breadcrumbs/${slug}`)
            .then((res) => {
                this.setState({breadcrumbItems: res.data.breadcrumbItems, breadcrumbCurrent: res.data.breadcrumbCurrent});
            });
    }

    render() {
        const {slug} = this.props;
        const seriesArray =  (this.state.relatedSeries.length > 0) ? this.state.relatedSeries.map((seria) => { return seria.title }).join( ', ' ) : "не указаны";

        return (
            <Fragment>
                <Header/>
                <MainContent>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{this.state.seo.title}</title>
                        <meta name="description" content={this.state.seo.description} />
                        <meta name="keywords" content={this.state.seo.keywords} />
                    </Helmet>
                    <Breadcrumbs items={this.state.breadcrumbItems} activeItem={this.state.breadcrumbCurrent} />
                    <VitrageContent url={`/api/get_project_info/${slug}`} getRelatedSeries={this.getRelatedSeries} getNextProject={this.getNextProject}/>
                </MainContent>
                {Object.keys(this.state.nextProject).length > 0 && <NextCard data={this.state.nextProject}/>}
                <Footer/>
            </Fragment>
        );
    }
};

export default ProjectPage;
