import React, { PureComponent } from "react";
import ImgsViewer from "../../libs/react-images-viewer";
import { extractionFullImg } from "../../utils/extractionFullImg";

class SeriesSlider extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      currImage: 0,
    };

    this.swiper = null;
  }

  handleClickNext = () => {
    this.setState((prevState) => ({
      ...prevState,
      currImage: prevState.currImage + 1,
    }));
  };

  handleClickPrev = () => {
    this.setState((prevState) => ({
      ...prevState,
      currImage: prevState.currImage - 1,
    }));
  };

  handleCloseView = () => {
    this.setState({
      ...this.state,
      isOpen: false,
    });
  };

  handleOpenView = (index) => {
    this.setState((prevState) => ({
      ...prevState,
      isOpen: true,
      currImage: index,
    }));
  };

  async componentDidMount() {
    this.swiper = new Swiper(".swiper-container.swiper-seria", {
      loop: false,
      autoHeight: true,
      navigation: {
        nextEl: ".swiper-button-next.swiper-seria__next",
        prevEl: ".swiper-button-prev.swiper-seria__prev",
      },
      breakpoints: {
        800: {
          autoHeight: true,
          spaceBetween: 24,
          initialSlide: 0,
          slidesPerView: "auto",
          loop: false, // if true apears bug in Image Viewer
        },
      },
    });
  }

  componentWillUnmount() {
    this.swiper = null;
  }

  render() {
    const { slides } = this.props;
    const slidesURLArray = !!slides && extractionFullImg(slides);

    return (
      <>
        {!!slides && (
          <ImgsViewer
            imgs={slidesURLArray}
            width={1920}
            showImgCount={false}
            spinnerDisabled={true}
            closeBtnTitle="Закрыть изображение"
            leftArrowTitle="Предыдущее изображение"
            rightArrowTitle="Следующее изображение"
            backdropCloseable={true}
            currImg={this.state.currImage}
            isOpen={this.state.isOpen}
            onClickPrev={this.handleClickPrev}
            onClickNext={this.handleClickNext}
            onClose={this.handleCloseView}
          />
        )}

        <div className="swiper-container swiper-seria">
          <div className="swiper-wrapper swiper-seria-wrapper">
            {slides.map((slide, index) => {
              return (
                <div
                  onClick={() => this.handleOpenView(index)}
                  key={`slide-${index}`}
                  className={
                    slide.isvertical
                      ? "swiper-slide swiper-seria-slide isvertical"
                      : "swiper-slide swiper-seria-slide"
                  }
                >
                  <img
                    className="swiper-seria__img"
                    alt={slide.title}
                    src={slide.imageUrl}
                  />
                </div>
              );
            })}
          </div>
          <div className="swiper-button-prev swiper-seria__prev">prev</div>
          <div className="swiper-button-next swiper-seria__next">next</div>
        </div>
      </>
    );
  }
}

export default SeriesSlider;
