import React, {Fragment, PureComponent} from 'react';
import Header from "../items/header";
import Footer from "../items/footer";
import MainContent from "../items/main-content";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Operations} from "../../reducers/projects/projects";
import ProjectsList from "../items/projects_list";
import {Urls} from "../../utils";
import AnimationBase from "../items/animation-base";
import Breadcrumbs from '../items/breadcrumbs';

class ProjectsPage extends PureComponent {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {getProjects} = this.props;


        getProjects();
    }

    render() {
        const {projects} = this.props;

        return(
            <div className="projects-page">
                <Header/>
                <MainContent>
                    <Breadcrumbs items={ [ { title:"Urfo", url:"/" } ]} activeItem={{ title:"Проекты", url:"/projects" }} />
                    <div className="container grid-container">
                        <h1 className="h1-title">
                            <AnimationBase>Проекты</AnimationBase>
                        </h1>
                        <ProjectsList projects={projects}/>
                    </div>
                </MainContent>
                <Footer/>
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        projects: state.projects.projects
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getProjects: bindActionCreators(Operations.getProjects, dispatch),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPage);
