import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {Urls} from "../../utils";

class MainSliderMobile extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            currentSlide: 0
        };
        this.swiper = null;
        this.changeSlideHandler = this.changeSlideHandler.bind(this);
    }

    changeSlideHandler() {
        this.setState({
            currentSlide: this.swiper.realIndex
        });
    }

    async componentDidMount() {
        const response = await axios.get('/api/get_sliders');
        this.setState({
            slides: response.data
        });
        this.swiper = new Swiper('.swiper-container-main-slider-js',
            {
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                }
            });

        this.swiper.on('slideChange', this.changeSlideHandler);
    }

    componentWillUnmount() {
        this.swiper = null;
    }

    render() {
        const {slides, currentSlide} = this.state;
        return (
            <div className="custom-slider-mobile">
                {slides.length > 0 && <div className="swiper-container swiper-container-main-slider-js">
                    <div className="swiper-wrapper">
                        {slides.map((slide, index) => {
                            return <Link to={`/${Urls.PROJECTS}/${slides[currentSlide].slug}`} className="swiper-slide" key={`slide-${index}`}>
                                <div className="swiper-image">
                                    <img src={slide.imageUrl} alt={slide.title}/>
                                </div>
                            </Link>;
                        })}
                    </div>
                    <div className="swiper-pagination"></div>
                </div>}
                {slides.length > 0 && <div className="container grid-container">
                    <Link className="custom-slider__title-link custom-slider__title-link--mobile" to={`/${Urls.PROJECTS}/${slides[currentSlide].slug}`} >
                        <span dangerouslySetInnerHTML={{ __html: slides[currentSlide].title }} />
                    </Link>
                </div>}
            </div>
        );
    }
};

export default MainSliderMobile;
