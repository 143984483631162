import React from "react";
import { Urls } from "../../utils";
import { Link } from "react-router-dom";

const Breadcrumbs = (props) => {
  const { items, activeItem } = props;

  return (
    <ol
      className="breadcrumbs-wrap container"
      itemScope=""
      itemType="https://schema.org/BreadcrumbList"
    >
      {items.length > 0 &&
        items.map((item, index) => {
          return (
            <li
              itemScope=""
              itemProp="itemListElement"
              itemType="https://schema.org/ListItem"
              key={`crumb-${index}`}
            >
              <a
                itemProp="item"
                href={item.url}
                className="breadcrumbs--link"
              >
                <span itemProp="name">{item.title}</span>
                <meta itemProp="position" content={index + 1} />
              </a>
            </li>
          );
        })}
      <li itemProp="item">
        <span itemProp="name">{activeItem.title}</span>
      </li>
    </ol>
  );
};

export default Breadcrumbs;
