import React from 'react';
import {Link} from "react-router-dom";

const CatalogSliderMobile = (props) => {
    const {slides} = props;

    if (!slides.length) {
        return null;
    }

    const swiper = new Swiper('.swiper-container-slider-mobile-js',
        {
            loop: true
            // pagination: {
            //     el: '.swiper-pagination--catalog',
            //     clickable: true
            // }
        });

    return (
        <div className="catalog-slider-mobile">
            <div className="swiper-container swiper-container-slider-mobile-js">
                <div className="swiper-wrapper">
                    {slides.map((good, index) => {
                        return <div className="swiper-slide" key={`good-${index}`}>
                            <div className="catalog__item">
                                <Link to={`/${good.model}/${good.slug}`} className="catalog__img">
                                    <img src={good.imageUrl} alt={good.title}/>
                                </Link>
                                <Link to={`/${good.model}/${good.slug}`} className="catalog__title">{good.title}</Link>
                                <div className="catalog__desc">{good.shortDescription}</div>
                            </div>
                        </div>;
                    })}
                </div>
                {/*<div className="swiper-pagination swiper-pagination--catalog"></div>*/}
            </div>
        </div>
    );
};

export default CatalogSliderMobile;
