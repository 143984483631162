const initialState = {
    smallHomeSeries: [],
    bigHomeSeries: [],
    seriesPageInfo: null
};

const ActionType = {
    SET_SMALL_HOME_SERIES: `GET_SMALL_HOME_SERIES`,
    SET_BIG_HOME_SERIES: `GET_BIG_HOME_SERIES`,
    SET_SERIES_PAGE_INFO: `SET_SERIES_PAGE_INFO`,
    CLEAR_SERIES_PAGE_INFO: `CLEAR_SERIES_PAGE_INFO`
};

const ActionCreator = {
    setSmallHomeSeries: (series) => ({
        type: ActionType.SET_SMALL_HOME_SERIES,
        payload: series
    }),
    setBigHomeSeries: (series) => ({
        type: ActionType.SET_BIG_HOME_SERIES,
        payload: series
    }),
    setSeriesPageInfo: (pageInfo) => ({
        type: ActionType.SET_SERIES_PAGE_INFO,
        payload: pageInfo
    }),
    clearSeriesPageInfo: () => ({
        type: ActionType.CLEAR_SERIES_PAGE_INFO,
        payload: null
    })
};

const Operations = {
    getSmallHomeSeries: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_small_home_series`)
            .then((response) => {
                dispatch(ActionCreator.setSmallHomeSeries(response.data));
            });
    },
    getBigHomeSeries: () => (dispatch, _getState, api) => {
        return api.get(`/api/get_big_home_series`)
            .then((response) => {
                dispatch(ActionCreator.setBigHomeSeries(response.data));
            });
    },
    getSeriesPageInfo: (slug) => (dispatch, _getState, api) => {
        return api.get(`/api/get_series_info/${slug}`)
            .then((response) => {
                dispatch(ActionCreator.setSeriesPageInfo(response.data));
            });
    }
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionType.SET_SMALL_HOME_SERIES: return Object.assign({}, state, {
            smallHomeSeries: action.payload
        });
        case ActionType.SET_BIG_HOME_SERIES: return Object.assign({}, state, {
            bigHomeSeries: action.payload
        });
        case ActionType.SET_SERIES_PAGE_INFO: return Object.assign({}, state, {
            seriesPageInfo: action.payload
        });
        case ActionType.CLEAR_SERIES_PAGE_INFO: return Object.assign({}, state, {
            seriesPageInfo: action.payload
        });
    }

    return state;
};

export {
    reducer,
    ActionCreator,
    ActionType,
    Operations
};
