import React, { Fragment } from 'react';
import { ActionCreator } from "../../reducers/feedback/feedback";
import { connect, useDispatch } from 'react-redux';

const FooterSocials = ({contacts,footermenu}) => {
    const dispatch = useDispatch();

    return (
        <Fragment>
            <div className="footer__socials">
                <div className="footer__socials_title">
                    { contacts.instaLink && <a className="footer__socials_title_link" href="https://www.instagram.com/explore/tags/urbanforms_urfo/" target="_blank">#urbanforms_urfo</a> }
                </div>

                <div className="footer__socials_links">
                    { contacts.instaLink && <a href={contacts.instaLink} className="socials__link" target="_blank">
                        <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1" width="24" height="24" rx="5.5" stroke="#FDB913" strokeWidth="2"/>
                            <path d="M19.6442 4.55234C19.8579 4.4095 20.1093 4.33325 20.3664 4.33325C20.7112 4.33325 21.0418 4.47022 21.2856 4.71401C21.5294 4.95781 21.6664 5.28847 21.6664 5.63325C21.6664 5.89037 21.5902 6.14171 21.4473 6.35549C21.3045 6.56928 21.1014 6.7359 20.8639 6.8343C20.6264 6.93269 20.365 6.95843 20.1128 6.90827C19.8606 6.85811 19.629 6.7343 19.4472 6.55249C19.2654 6.37068 19.1415 6.13904 19.0914 5.88687C19.0412 5.63469 19.067 5.37331 19.1654 5.13576C19.2638 4.89822 19.4304 4.69519 19.6442 4.55234Z" fill="#FDB913" stroke="#FDB913"/>
                            <circle cx="12.9993" cy="13.0001" r="5.33333" stroke="#FDB913" strokeWidth="2"/>
                        </svg>
                    </a> }

                    { contacts.facebookLink && <a href={contacts.facebookLink} className="socials__link" target="_blank">
                        <svg width="15" height="30" viewBox="0 0 15 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 10.1204V14.3903H4.64V29H9.32V14.3903H12.8182L14 10.1204H9.32V6.62821C9.32 6.21565 9.39724 5.81999 9.61887 5.52827C9.84051 5.23654 10.1411 4.99677 10.4545 4.99677H14V1H10.4545C8.88736 1 6.87484 1.34784 5.76667 2.80645C4.6585 4.26507 4.64 5.71155 4.64 7.77435V10.1204H1Z" stroke="#FDB913" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </a> }
                </div>
                <div className="footer__socials_feedback">
                    <button onClick={() => dispatch(ActionCreator.openFeedbackPopup())} className="footer__feedback-btn">Обратная связь</button>
                </div>
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        contacts: state.general.contacts,
        footermenu: state.general.footermenu
    }
};

export default connect(mapStateToProps)(FooterSocials);
