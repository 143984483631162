import React, {Fragment, PureComponent} from 'react';
import Header from "../items/header";
import MainContent from "../items/main-content";
import Footer from "../items/footer";
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import {Link} from 'react-router-dom';
import Breadcrumbs from '../items/breadcrumbs';

import ProjectsList from "../items/projects_list";
import NextCard from "../items/next-card";
import {Operations as OperationsSeries, ActionCreator} from "../../reducers/series/series";
import {Operations as OperationsProjects} from "../../reducers/projects/projects";
import {Urls} from "../../utils";
import {Helmet} from "react-helmet"
import AnimationBase from "../items/animation-base";
import SeriesSlider from "../items/series-slider";

class SeriesPage extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            currentWidthWindow: window.innerWidth
        };
        this.resizeWindowHandler = this.resizeWindowHandler.bind(this);
    }

    resizeWindowHandler() {
        this.setState({
            currentWidthWindow: window.innerWidth
        });
    }

    componentDidMount() {
        const {getSeriesPageInfo, getSeriesProject, slug} = this.props;
        getSeriesPageInfo(slug);
        getSeriesProject(slug);
        window.addEventListener('resize', this.resizeWindowHandler);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizeWindowHandler);
        const {clearSeriesPageInfo} = this.props;
        clearSeriesPageInfo();
    }

    componentWillReceiveProps(nextProps) {
        const {slug} = this.props;
        if (nextProps.slug !== slug) {
            const {getSeriesPageInfo, getSeriesProject} = this.props;
            getSeriesPageInfo(nextProps.slug);
            getSeriesProject(nextProps.slug);
        }
    }

    render() {
        const {seriesPageInfo, seriesProject} = this.props;
        const {currentWidthWindow} = this.state;

        if (!seriesPageInfo) {
            return (
                <Fragment>
                    <Header isActive={true}/>
                    <Footer/>
                </Fragment>
            );
        }

        const {title, description, breadcrumbItems, breadcrumbCurrent, goods, next_series, seo, seriesSliderImgs } = seriesPageInfo;
        const twoColumnClass = ((goods.length > 3) || currentWidthWindow < 900)? ` two-column` : ``;

        return (
            <Fragment>
                <Header isActive={true}/>
                <MainContent>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{seo.title}</title>
                        <meta name="description" content={seo.description} />
                        <meta name="keywords" content={seo.keywords} />
                    </Helmet>
                    <Breadcrumbs items={breadcrumbItems} activeItem={breadcrumbCurrent} />
                    <div className="container grid-container">
                        <AnimationBase classDiv="card-series__content">
                            <div className="card-series__title">{title}</div>
                            <div className="card-series__description">{description}</div>
                        </AnimationBase>
                    </div>
                    <div className="grid-container">
                        <AnimationBase classDiv="card-series__slider_wrap">
                            <div className="card-series__slider_inner">
                               { seriesSliderImgs.length > 0 && <SeriesSlider slides={seriesSliderImgs}/> }
                            </div>
                        </AnimationBase>
                    </div>

                    {goods.length > 0 && <div className="container grid-container">
                        <AnimationBase classDiv={`card-series__goods goods-series${twoColumnClass}`}>
                            {goods.map((good, index) => {
                                return <Link
                                    itemScope=""
                                    itemType="https://schema.org/ImageObject"
                                    key={`good-${index}`}
                                    to={`/${Urls.GOODS}/${good.slug}`}
                                    className={ good.imageUrlHover ? "goods-series__item with-hover" :"goods-series__item"}
                                >
                                    { good.imageUrlHover ? <img className="goods-series__img is-hover" loading="lazy" alt={good.title} src={good.imageUrlHover}/> : ""}
                                    <img itemProp="contentUrl" className="goods-series__img no-hover" src={good.imageUrl} loading="lazy" alt={good.title} />
                                    <div itemProp="name" className="goods-series__title">{good.title}</div>
                                </Link>;
                            })}
                        </AnimationBase>
                    </div>}
                    {seriesProject.length > 0 && <div className="container grid-container">
                        <div className={"projects-inseries-border"}><h3>Проекты</h3></div>
                        <ProjectsList projects={seriesProject}/>
                    </div>}

                </MainContent>
                <NextCard data={next_series}/>
                <Footer/>
            </Fragment>
        );
    }
};

const mapStateToProps = (state) => ({
    seriesPageInfo: state.series.seriesPageInfo,
    seriesProject: state.projects.seriesProject
});

const mapDispatchToProps = (dispatch) => ({
    getSeriesPageInfo: bindActionCreators(OperationsSeries.getSeriesPageInfo, dispatch),
    clearSeriesPageInfo: () => dispatch(ActionCreator.clearSeriesPageInfo()),
    getSeriesProject: bindActionCreators(OperationsProjects.getSeriesProject, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SeriesPage);
